import DashboardPageHeader from "../../../../components/dashboard-header/DashboardPageHeader";
import { Row, Col, Card, Tag, Flex, Dropdown, Grid } from "antd";
import useDocumentTitle from "../../../../utils/useDocumentTitle";
import { useParams } from "react-router-dom";
import { detailBulk, refreshProgressBulk } from "../../../../api";
import { useCallback, useEffect, useState } from "react";
import Spinner from "../../../../components/Spinner/Spinner";
import { DetailCount } from "../../../../components/card/bulk/DetailCount";
import { DetailResult } from "../../../../components/card/bulk/DetailResult";
import {
  QuestionCircleFilled,
  ClockCircleFilled,
  CheckCircleFilled,
  MoreOutlined,
} from "@ant-design/icons";
import { ActionTableBulk } from "../../../../components/ActionTable";
import { useSelector } from "react-redux";
import { ModalInfoValidate } from "../../../../components/modal/ModalInfoValidate";

const { useBreakpoint } = Grid;

const DetailBulkValidation = () => {
  useDocumentTitle("Detail Bulk Validation");
  const { uuid } = useParams();
  const screen = useBreakpoint();
  const XlargeScreen = screen.xl;
  const [detailData, setDetailData] = useState(null);
  const [progressData, setProgressData] = useState(null);
  const [progressState, setProgressState] = useState(false);
  const [radioValue, setRadioValue] = useState(false);
  const [radioDownload, setRadioDownload] = useState("valid");
  const [openModalInfo, setOpenModalInfo] = useState(false);
  const [openModalDownload, setOpenModalDownload] = useState(false);
  const validated = detailData?.data?.status === "validated";
  const validating = detailData?.data?.status === "validating";
  const invalidated = detailData?.data?.status === "not-validated";
  const ProgressFinish = progressData?.data?.status === "validated";
  const FinishState = progressState && ProgressFinish;
  const user = useSelector(({ userData }) => userData.value);
  const currentQuota = user?.data?.totalQuotaAvailable;
  const lowQuota = currentQuota <= 10 && currentQuota >= 1;
  const notEnoughQuota = currentQuota <= 0;
  const runningOutQuota = currentQuota === 0;

  const getDetail = useCallback(async () => {
    const { data, statusCode } = await detailBulk({ uuid });
    setDetailData({ data, statusCode });
  }, [uuid]);

  const getProgressBulk = useCallback(async () => {
    const { data, statusCode } = await refreshProgressBulk({ uuid });
    setProgressData({ data, statusCode });
  }, [uuid]);

  useEffect(() => {
    if (!detailData) getDetail();
  }, [getDetail, detailData]);

  useEffect(() => {
    if (!progressData && validating) setProgressState(true);
    let intervalId;

    const fetchData = () => {
      if (progressState) {
        if (!progressData && validating) {
          getProgressBulk();
        } else {
          intervalId = setInterval(async () => {
            await getProgressBulk();
            if (FinishState) {
              clearInterval(intervalId);
              getDetail();
              setTimeout(() => {
                setProgressState(false);
              }, 1000);
            }
          }, 3100);
        }
      }
    };

    fetchData();

    return () => {
      clearInterval(intervalId);
    };
  }, [
    getProgressBulk,
    getDetail,
    progressState,
    FinishState,
    progressData,
    validating,
  ]);

  const props = {
    lowQuota,
    notEnoughQuota,
    runningOutQuota,
    invalidated,
    getDetail,
    getProgressBulk,
    setProgressState,
    radioValue,
    setRadioValue,
    setOpenModalInfo,
  };

  return (
    <>
      {!detailData ? (
        <Flex justify="center" align="center" className="h-screen">
          <Spinner />
        </Flex>
      ) : (
        <>
          <DashboardPageHeader
            title={detailData?.data?.name}
            extra={
              <Dropdown
                trigger={["click"]}
                overlay={() => (
                  <ActionTableBulk
                    record={detailData?.data}
                    openModalDownload={openModalDownload}
                    setOpenModalDownload={setOpenModalDownload}
                    radioDownload={radioDownload}
                    setRadioDownload={setRadioDownload}
                  />
                )}
              >
                <MoreOutlined />
              </Dropdown>
            }
          />
          <Row className="px-3 py-3 mx-0" gutter={24}>
            <Col xl={10} lg={24} md={24} sm={24} xs={24}>
              <Card
                {...(detailData && { title: "Bulk Email Details" })}
                {...(detailData && {
                  extra: (
                    <Tag
                      className={`font-medium text-xs mr-0 ${
                        validated
                          ? "text-info"
                          : invalidated
                          ? ""
                          : "text-magenta"
                      }`}
                      icon={
                        validated ? (
                          <CheckCircleFilled />
                        ) : invalidated ? (
                          <QuestionCircleFilled />
                        ) : (
                          <ClockCircleFilled />
                        )
                      }
                    >
                      {validated
                        ? "Validated"
                        : invalidated
                        ? "Not Validated"
                        : "Processing"}
                    </Tag>
                  ),
                })}
              >
                <DetailCount detailData={detailData?.data} props={props} />
              </Card>
            </Col>
            <Col
              xl={14}
              lg={24}
              md={24}
              sm={24}
              xs={24}
              className={`${!XlargeScreen && "pt-6"}`}
            >
              <Card>
                {!progressData && validating ? (
                  <Flex
                    justify="center"
                    align="center"
                    className="h-screen-360"
                  >
                    <Spinner />
                  </Flex>
                ) : (
                  <DetailResult
                    detailData={detailData?.data}
                    progressData={progressData?.data}
                    validated={validated}
                    invalidated={invalidated}
                    progressState={progressState}
                    FinishState={FinishState}
                    openModalDownload={openModalDownload}
                    setOpenModalDownload={setOpenModalDownload}
                    radioDownload={radioDownload}
                    setRadioDownload={setRadioDownload}
                  />
                )}
              </Card>
            </Col>
          </Row>
          <ModalInfoValidate
            openModalInfo={openModalInfo}
            setOpenModalInfo={setOpenModalInfo}
          />
        </>
      )}
    </>
  );
};

export default DetailBulkValidation;
