import { Table, Skeleton } from "antd";
import { Link } from "react-router-dom";
import {
  CheckCircleFilled,
  CloseCircleFilled,
  ExclamationCircleFilled,
} from "@ant-design/icons";

export const TableValidate = ({ latestData }) => {
  const columnValidate = [
    {
      title: "Email Address",
      dataIndex: "email",
      key: "Email",
      ellipsis: true,
      style: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        flex: "1 0 0",
      },
    },
    {
      dataIndex: "result",
      key: "Status",
      width: 40,
      style: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        flex: "1 0 0",
      },
      render: (values) =>
        values === "valid" ? (
          <CheckCircleFilled style={{ color: "#52C41A" }} />
        ) : values === "risky" ? (
          <ExclamationCircleFilled style={{ color: "#FAAD14" }} />
        ) : (
          <CloseCircleFilled style={{ color: "#FF4D4F" }} />
        ),
    },
  ];

  return (
    <div className="list-validate">
      <div className="flex flex-col">
        {!latestData?.rows ? (
          <Skeleton active paragraph={{ rows: 5 }} />
        ) : (
          <>
            <Table
              className="table_list_policies"
              columns={columnValidate}
              dataSource={latestData?.rows}
              pagination={false}
            />
            {latestData?.rows && (
              <div className="flex justify-end mt-4 mb-2 py-2">
                <Link className="text-success" to="/single-validation">
                  Lihat semua email
                </Link>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
