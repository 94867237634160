import { Suspense } from "react";
import Routing from "./Routing";
import { ConfigProvider } from "antd";
import useGetUser from "./utils/hooks/useGetUser";
import { useSelector } from "react-redux";
import { getSessionToken, getCookieToken } from "./utils/helpers";
import Spinner from "./components/Spinner/Spinner";

function App() {
  useGetUser();
  const user = useSelector(({ userData }) => userData.value);
  const token = getSessionToken() || getCookieToken();

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#0d9f3f",
          colorInfo: "#0d9f3f",
          borderRadius: 4,
          fontFamily: "Roboto",
          fontWeightStrong: 500,
        },
        components: {
          Alert: {
            colorInfo: "rgb(24, 144, 255)",
            colorInfoBg: "rgb(230, 247, 255)",
            colorInfoBorder: "rgb(24, 144, 255)",
            defaultPadding: "9px 16px",
          },
          Input: {
            activeBorderColor: "rgb(13, 159, 63)",
            hoverBorderColor: "rgb(13, 159, 63)",
          },
        },
      }}
    >
      <Suspense fallback={<Spinner full />}>
        <Routing user={user} token={token} />
      </Suspense>
    </ConfigProvider>
  );
}

export default App;
