import DashboardHeader from "../../../../components/dashboard-header/DashboardHeader";
import useDocumentTitle from "../../../../utils/useDocumentTitle";
import { Card, Col, Form, Row, Typography } from "antd";
import {
  ValidationEmailCard,
  ResultEmailCard,
} from "../../../../components/card/single/CardValidate";
import { TableSingleValidate } from "../../../../components/table/single/TableSingleValidate";
import { useState } from "react";
import { getUser, validateEmail, validateList } from "../../../../api";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ModalConfirm from "../../../../components/modal/ModalConfirm";
import { setUserData } from "../../../../redux/slices/user";
import { regexEmail } from "../../../../utils/helpers";
import ModalSuccess from "../../../../components/modal/ModalSuccess";
import { CheckCircleOutlined } from "@ant-design/icons";

const SingleValidation = () => {
  useDocumentTitle("Single Validation");
  const dispatch = useDispatch();
  const [processValidation, setProcessValidation] = useState(false);
  const [result, setResult] = useState(false);
  const [listRecord, setListRecord] = useState(false);
  const [dataValidate, setDataValidate] = useState(false);
  const [form] = Form.useForm();
  const [filterSingle, setFilterSingle] = useState({
    page: 1,
    limit: 10,
  });
  const [email, setEmail] = useState(false);
  const checkEmail = regexEmail.test(email);
  const [currentPage, setCurrentPage] = useState(filterSingle.page);
  const [currentLimit, setCurrentLimit] = useState("10");
  const [searchValue, setSearchValue] = useState("");
  const [showSkeleton, setShowSkeleton] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [lowState, setLowState] = useState(false);
  const [notEnoughState, setNotEnoughState] = useState(false);
  const [radioValue, setRadioValue] = useState(false);
  const [openModalInfo, setOpenModalInfo] = useState(false);
  const [showModalDetail, setShowModalDetail] = useState(false);
  const [showModalDetailList, setShowModalDetailList] = useState(false);
  const [alertSaveQuota, setAlertSaveQuota] = useState(false);
  const [saveState, setSaveState] = useState(false);
  const user = useSelector(({ userData }) => userData.value);
  const userID = user?.data?.uuid;
  const currentQuota = user?.data?.totalQuotaAvailable;
  const remainingBasicQuota =
    user?.data?.basicQuotaDetail?.totalQuota -
    user?.data?.basicQuotaDetail?.quotaUsage;
  const remainingAdvanceQuota =
    user?.data?.advanceQuotaDetail?.totalQuota -
    user?.data?.advanceQuotaDetail?.quotaUsage;
  const lowQuota = currentQuota <= 10 && currentQuota >= 1;
  const notEnoughQuota = currentQuota < 1 || currentQuota <= 0;
  const runningOutQuota = currentQuota === 0;

  const getListValidate = useCallback(async () => {
    setShowSkeleton(true);
    const { data, statusCode } = await validateList(filterSingle);
    setDataValidate({ data, statusCode });
    setTimeout(() => setShowSkeleton(false), 1500);
  }, [filterSingle]);

  const getDataUser = useCallback(async () => {
    const { data } = await getUser({ uuid: userID });
    dispatch(setUserData({ data }));
  }, [dispatch, userID]);

  useEffect(() => {
    getListValidate();
  }, [getListValidate]);

  const handleSearch = (value) => {
    setSearchValue(value);
  };

  const changeSearchQuery = (query) => {
    setCurrentPage(1);
    setFilterSingle({
      ...filterSingle,
      page: 1,
      email: query,
    });
  };

  const changePage = (page) => {
    setCurrentPage(page);
    setFilterSingle({
      ...filterSingle,
      page,
    });
  };

  const changePageLimit = (value) => {
    setCurrentLimit(value);
    setCurrentPage(1);
    setFilterSingle({
      ...filterSingle,
      page: 1,
      limit: value,
    });
  };

  const singleValidate = async (force = false) => {
    const value = form.getFieldsValue();
    setProcessValidation(true);
    setSaveState(false);
    const payload = {
      email: value.email,
      type: radioValue,
      ...(force ? { force: true } : { force: false }),
    };

    const finishValidate = () => {
      setProcessValidation(false);
      setSaveState(false);
      setTimeout(async () => {
        await getDataUser();
        await getListValidate();
        setShowSpinner(false);
      }, 1000);
    };

    const response = await validateEmail(payload);

    if (response) {
      const isExistingValidationTypeSame =
        response?.data?.isExistingValidationTypeSame;
      const isValidationExist = response?.data?.isValidationExist;
      const type = response?.data?.type;

      setSaveState(true);
      setShowSpinner(true);

      if (isExistingValidationTypeSame && isValidationExist) {
        setAlertSaveQuota(true);
        ModalSuccess({
          title: "Alamat email telah divalidasi sebelumnya",
          customMessage: (
            <Typography.Text>
              Alamat email <span className="font-medium">{email}</span> telah
              divalidasi sebelumnya, anda tidak perlu melakukan validasi
              kembali. <br /> <br />
              Data validation result yang tampil berdasarkan data yang ada,
              tidak mengurangi kuota validasi anda.
            </Typography.Text>
          ),
          width: 500,
          okText: "Oke",
          icon: <CheckCircleOutlined className="check-single" />,
          type: "primary",
        });
        setResult(response);
        finishValidate();
      } else if (!isExistingValidationTypeSame && isValidationExist && !force) {
        setAlertSaveQuota(false);
        ModalConfirm({
          title: "Alamat email telah divalidasi sebelumnya",
          customMessage: (
            <Typography.Text>
              Alamat email <span className="font-medium">{email}</span> telah
              divalidasi sebelumnya dengan menggunakan{" "}
              <span className="font-medium">
                {type === "basic" ? "Advanced" : "Basic"} Validation.
              </span>
              <br /> <br />
              Apakah anda ingin melakukan validasi kembali menggunakan tipe
              validasi berbeda?
            </Typography.Text>
          ),
          width: 500,
          okText: "Ya, lakukan validasi ulang",
          cancelText: "Tidak",
          icon: <CheckCircleOutlined className="check-single" />,
          type: "primary",
          onOk: () => {
            singleValidate(true);
          },
          onCancel: () => {
            setResult(response);
            setAlertSaveQuota(true);
            finishValidate();
          },
        });
      } else {
        setResult(response);
        setAlertSaveQuota(false);
        finishValidate();
      }
    } else {
      setAlertSaveQuota(false);
      setProcessValidation(false);
    }
  };

  const handleValidation = async () => {
    if (
      notEnoughQuota ||
      runningOutQuota ||
      (remainingBasicQuota === 0 && radioValue === "basic") ||
      (remainingAdvanceQuota === 0 && radioValue === "advance")
    ) {
      ModalConfirm({
        title: "Gagal melakukan validasi email",
        customMessage: (
          <span className="text-sm">
            {notEnoughQuota || runningOutQuota ? (
              <span className="text-sm">
                <span>
                  Validasi email gagal dilakukan, karena anda tidak memiliki
                  <br /> kuota validasi yang cukup untuk melakukan validasi.
                  Silakan
                  <br />
                  dapat melakukan penambahan kuota.
                </span>
              </span>
            ) : (
              <span>
                Validasi email gagal dilakukan, karena anda tidak memiliki
                <br /> kuota{" "}
                <span className="font-bold">
                  {radioValue === "basic"
                    ? "Basic Validation"
                    : "Advanced Validation"}
                </span>{" "}
                yang cukup.
                <br />
                <br />
                Silakan dapat melakukan penambahan kuota tersebut atau melakukan
                validasi menggunakan Advanced Validation.
              </span>
            )}
          </span>
        ),
        type: "primary",
        width: 500,
        okText: "Oke",
        cancelText: "Tambah Kuota",
        href: "https://www.aktiva.co.id/email-validator-order/",
        target: "_blank",
      });
    } else {
      setLowState(false);
      setNotEnoughState(false);
      singleValidate();
    }
  };

  const handleChangeEmail = (value) => {
    setEmail(value);
    if (result && result.data.email !== value) {
      if (lowQuota) setLowState(true);
      if (notEnoughQuota) setNotEnoughState(true);
    }
  };

  const openModalDetail = () => {
    setShowModalDetail(true);
  };

  const closeModalDetail = () => {
    setShowModalDetail(false);
  };

  const openModalDetailList = (record) => {
    setShowModalDetailList(true);
    setListRecord(record);
  };

  const closeModalDetailList = () => {
    setShowModalDetailList(false);
    setListRecord(false);
  };

  const props = {
    lowQuota,
    notEnoughQuota,
    runningOutQuota,
    checkEmail,
    handleChangeEmail,
    lowState,
    notEnoughState,
    setRadioValue,
    radioValue,
    setOpenModalInfo,
    openModalInfo,
    showModalDetail,
    openModalDetail,
    closeModalDetail,
    showModalDetailList,
    openModalDetailList,
    closeModalDetailList,
    listRecord,
    setAlertSaveQuota,
    alertSaveQuota,
    saveState,
  };

  return (
    <>
      <DashboardHeader title="Single Validation Email" />
      <Row gutter={24} className="px-3 py-3 card-validate">
        <Col xl={12} lg={24} md={24} sm={24} xs={24}>
          <Card title="Validate Email">
            <ValidationEmailCard
              handleValidation={handleValidation}
              processValidation={processValidation}
              form={form}
              result={result}
              props={props}
            />
          </Card>
        </Col>
        <Col xl={12} lg={24} md={24} sm={24} xs={24} className="result-valid">
          <Card title="Validation Email Result">
            <ResultEmailCard
              result={result}
              showSpinner={showSpinner}
              props={props}
            />
          </Card>
        </Col>
      </Row>
      <Row className="flex-col px-6 pt-2">
        <Col span={24}>
          <Card>
            <TableSingleValidate
              handleSearch={handleSearch}
              changeSearchQuery={changeSearchQuery}
              changePage={changePage}
              changePageLimit={changePageLimit}
              currentPage={currentPage}
              currentLimit={currentLimit}
              searchValue={searchValue}
              showSkeleton={showSkeleton}
              dataValidate={dataValidate}
              setFilterSingle={setFilterSingle}
              filterSingle={filterSingle}
              processValidation={processValidation}
              getListValidate={getListValidate}
              props={props}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default SingleValidation;
