import { authLogout } from "../api";
import { setLogout } from "./helpers";
import { getCookieRefreshToken } from "./helpers";

export const handleLogout = async () => {
  const refreshToken = getCookieRefreshToken();
  const { statusCode, msg } = await authLogout({ refreshToken });
  if (statusCode === 200) {
    setLogout();
    window.location.href = "/login";
  } else {
    console.log(statusCode, msg);
  }
};
