import { PageHeader } from "@ant-design/pro-layout";
import React from "react";
import { useNavigate } from "react-router-dom";

const DashboardPageHeader = ({ title = "", extra, backPath = -1 }) => {
  const navigate = useNavigate();
  const back = () => {
    navigate(backPath);
  };

  return (
    <PageHeader
      className="page-header bg-white"
      extra={extra}
      onBack={back}
      title={title}
    />
  );
};

export default DashboardPageHeader;
