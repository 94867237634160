import HeaderSection from "../../../../components/header/HeaderSection";
import { useState } from "react";
import VerifikasiEmail from "./VerifikasiEmail";
import { Alert, Steps } from "antd";
import VerifikasiSuccess from "./VerifikasiSuccess";
import "./verifikasi.less";
import ClaimQuota from "./ClaimQuota";
import { getCookie } from "../../../../utils/helpers";

const Verifikasi = ({ user }) => {
  const { status = user?.data?.status } = user;

  const setup = status === "active" ? 1 : 0;
  const [currentStep, setCurrentStep] = useState(setup);
  const [claimQuota, setClaimQuota] = useState(false);
  const [afterVerif, setAfterVerif] = useState(false);
  const companyUUID = afterVerif?.companyUuid;
  const setStep = setCurrentStep;
  const next = (getNow) => {
    setStep(currentStep + 1);
    window.scrollTo({ left: 0, top: 0, behavior: "smooth" });
    setClaimQuota(getNow);
  };

  const getRefreshToken = getCookie("token_validation_refresh");

  const step = {
    currentStep,
    next,
    user,
    claimQuota,
    setAfterVerif,
    afterVerif,
    companyUUID,
    getRefreshToken,
    title:
      currentStep === 0
        ? "Verifikasi Email"
        : currentStep === 1
        ? "Klaim Kuota"
        : "Aktivasi Selesai",
  };
  const alertRender = currentStep === 0 && (
    <Alert
      className="w-full alert-data justify-center"
      message="Akun anda dalam proses verifikasi email. Lakukan verifikasi untuk melanjutkan registrasi layanan. "
      type="warning"
      showIcon
      closable
    />
  );
  const stepList = [
    {
      step: 1,
      title: "Verifikasi Email",
      content: <VerifikasiEmail {...step} />,
    },
    {
      step: 2,
      title: "Klaim Kuota",
      content: <ClaimQuota {...step} />,
    },
    {
      step: 3,
      title: "Aktivasi Selesai",
      content: <VerifikasiSuccess {...step} />,
    },
  ];

  const items = stepList.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  return (
    <div className="verifikasi-user">
      <HeaderSection />
      <div className="p-2">{alertRender}</div>
      <div className="box-step mx-auto">
        <div className="container-step py-7">
          <Steps current={currentStep} className="py-3" items={items} />
        </div>
        <div className="steps-content">{stepList[currentStep]?.content}</div>
      </div>
    </div>
  );
};

export default Verifikasi;
