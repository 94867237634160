import { Card, Col, Row, Skeleton } from "antd";
import {
  SummaryRadioButton,
  ChartRadioButton,
} from "../../../components/radio/RadioButton";
import { useCallback, useEffect, useState } from "react";
import useDocumentTitle from "../../../utils/useDocumentTitle";
import EmailValidationSummary from "./EmailValidationSummary";
import {
  LineChart,
  CircleChart,
} from "../../../components/chart/ChartDashboard";
import { TableValidate } from "../../../components/table/main/TableValidate";
import { latestValidate, reportEmail, summaryEmail } from "../../../api";
import { EmptyValidatedLatest } from "../../../components/empty/EmptyComponent";
import { useSelector } from "react-redux";

const MainDashboard = () => {
  useDocumentTitle("Dashboard");
  const [statistic, setStatistic] = useState("all");
  const [chart, setChart] = useState("week");
  const [statisticData, setStatisticData] = useState(null);
  const [pieData, setPieData] = useState(null);
  const [chartData, setChartData] = useState(null);
  const [latestData, setLatestData] = useState(null);
  const dataPieAvail =
    pieData && (pieData?.data?.totalValid && pieData?.data?.totalInvalid) !== 0;

  const user = useSelector(({ userData }) => userData.value);

  const isBasicQuota = statisticData?.data?.basicQuotaDetail;
  const isAdvancedQuota = statisticData?.data?.advanceQuotaDetail;
  const basicQuotaAvail = statisticData?.data?.basicQuotaDetail?.quotaAvailable;
  const percentageBasicUsed =
    statisticData?.data?.basicQuotaDetail?.usagePercentage;
  const totalBasicQuota = statisticData?.data?.basicQuotaDetail?.totalQuota;
  const advancedQuotaAvail =
    statisticData?.data?.advanceQuotaDetail?.quotaAvailable;
  const percentageAdvancedUsed =
    statisticData?.data?.advanceQuotaDetail?.usagePercentage;
  const totalAdvancedQuota =
    statisticData?.data?.advanceQuotaDetail?.totalQuota;

  const propsUser = {
    isBasicQuota,
    isAdvancedQuota,
    basicQuotaAvail,
    advancedQuotaAvail,
    percentageBasicUsed,
    percentageAdvancedUsed,
    totalBasicQuota,
    totalAdvancedQuota,
  };

  const getEmailSummary = useCallback(async (range) => {
    const { data } = await summaryEmail({ range });
    setStatisticData({ data });
    if (range === "all") setPieData({ data });
  }, []);

  const getEmailReports = useCallback(async () => {
    const { data, statusCode } = await reportEmail({ range: chart });
    setChartData({ data, statusCode });
  }, [chart]);

  const getLatestValidate = useCallback(async () => {
    const { data, statusCode } = await latestValidate();
    setLatestData({ data, statusCode });
  }, []);

  useEffect(() => {
    if (statistic) {
      getEmailSummary(statistic);
    }
  }, [getEmailSummary, statistic]);

  useEffect(() => {
    if (chart) getEmailReports();
  }, [getEmailReports, chart]);

  useEffect(() => {
    getLatestValidate();
  }, [getLatestValidate]);

  return (
    <div className="main-dashboard-user flex flex-col gap-y-6 px-6 py-4">
      <Card
        title="Email Validation Summary"
        className="card-wrapper-dashboard"
        extra={
          <SummaryRadioButton
            value={statistic}
            radio={statistic}
            setRadio={setStatistic}
          />
        }
      >
        <Row wrap justify="space-between">
          {!statisticData ? (
            <Skeleton active title={false} paragraph={{ rows: 3 }} />
          ) : (
            <EmailValidationSummary
              statisticData={statisticData?.data}
              isClaimQuota={user?.data?.isClaimQuota}
              propsUser={propsUser}
              companyUUID={user?.data?.companyUuid}
            />
          )}
        </Row>
      </Card>
      <Row>
        <Col xl={16} lg={24} md={24} sm={24} xs={24} className="grapic-card">
          <Card
            title="Grafik Validasi Email"
            extra={
              <ChartRadioButton
                radio={chart}
                setRadio={setChart}
                value={chart}
              />
            }
          >
            {chartData?.statusCode === 200 && (
              <span className="text-lg">
                Validasi dalam
                {chart === "month" ? " 1 bulan" : " 1 minggu"}
              </span>
            )}
            {!chartData ? (
              <Skeleton active paragraph={{ rows: 10 }} />
            ) : (
              <LineChart data={chartData?.data?.rows} />
            )}
          </Card>
        </Col>
        <Col xl={8} lg={24} md={24} sm={24} xs={24} className="validate-table">
          <Row gutter={16}>
            <Col
              {...(dataPieAvail
                ? { xl: 24, lg: 12, md: 12, sm: 24, xs: 24 }
                : { xl: 24, lg: 24, md: 24, sm: 24, xs: 24 })}
            >
              <Card title="Latest Validated Email">
                {latestData?.data?.total === 0 ? (
                  <EmptyValidatedLatest />
                ) : (
                  <TableValidate latestData={latestData?.data} />
                )}
              </Card>
            </Col>
            {!(statisticData && chartData && latestData) && (
              <Col
                {...(dataPieAvail
                  ? { xl: 24, lg: 12, md: 12, sm: 24, xs: 24 }
                  : { xl: 24, lg: 24, md: 24, sm: 24, xs: 24 })}
                className="pie-chart"
              >
                <Card>
                  <Skeleton active title={false} paragraph={{ rows: 5 }} />
                </Card>
              </Col>
            )}
            {dataPieAvail && (
              <Col
                xl={24}
                lg={12}
                md={12}
                sm={24}
                xs={24}
                className="pie-chart"
              >
                <CircleChart dataPie={pieData?.data} />
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default MainDashboard;
