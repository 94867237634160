import { InfoCircleOutlined } from "@ant-design/icons";
import {
  Col,
  Divider,
  Tooltip,
  Typography,
  Progress,
  Button,
  Flex,
} from "antd";
import { formatNumberWithDot } from "../../../utils/helpers";
import { ICArrowRightOutlined } from "../../../components/list/ListIcon";
import { BtnClaimQuota } from "../../../components/AntButton";

const { Title, Text } = Typography;

const UsedQuota = ({ statisticData, isClaimQuota, propsUser, companyUUID }) => {
  const {
    isBasicQuota,
    isAdvancedQuota,
    basicQuotaAvail,
    advancedQuotaAvail,
    percentageBasicUsed,
    percentageAdvancedUsed,
    totalBasicQuota,
    totalAdvancedQuota,
  } = propsUser;

  const allQuotaAvail =
    isBasicQuota &&
    isAdvancedQuota &&
    totalBasicQuota !== 0 &&
    totalAdvancedQuota !== 0;

  const colorStroke =
    statisticData?.totalQuotaUsagePercentage <= 80
      ? "#0D9F3F"
      : statisticData?.totalQuotaUsagePercentage <= 90
      ? "#FAAD14"
      : "#FF4D4F";

  return (
    <>
      <div className="flex justify-between items-center">
        <div className="text-secondary text-sm">
          {statisticData?.totalQuota === null
            ? !isClaimQuota
              ? "Tidak ada kredit tersedia"
              : "Tidak ada kredit tersedia"
            : "Penggunaan Kredit"}
        </div>
      </div>
      {statisticData?.totalQuota === null ? (
        <div className="flex justify-between items-center">
          {!isClaimQuota ? (
            <BtnClaimQuota
              className={"w-full my-3"}
              companyUUID={companyUUID}
            />
          ) : (
            <Button
              icon={<ICArrowRightOutlined />}
              type="primary"
              className="w-full my-3"
            >
              Beli Kredit
            </Button>
          )}
        </div>
      ) : (
        <>
          <div
            className={`flex justify-between items-center ${
              !allQuotaAvail && "pt-2 pb-1"
            }`}
          >
            {allQuotaAvail ? (
              <Title
                level={3}
                className="mt-0 title-percentage"
                style={{ marginBottom: 0 }}
              >
                {statisticData?.totalQuotaUsagePercentage}%
              </Title>
            ) : totalBasicQuota === 0 || totalAdvancedQuota === 0 ? (
              <Text level={3} className="text-base font-medium">
                {isBasicQuota &&
                  totalBasicQuota !== 0 &&
                  `Basic (${percentageBasicUsed}%)`}
                {isAdvancedQuota &&
                  totalAdvancedQuota !== 0 &&
                  `Advanced (${percentageAdvancedUsed}%)`}
              </Text>
            ) : undefined}
            <Text type="secondary" className="text-xs">
              {formatNumberWithDot(statisticData?.totalQuotaUsage)}/
              {formatNumberWithDot(statisticData?.totalQuota)}
            </Text>
          </div>
          <Progress
            percent={statisticData?.totalQuotaUsagePercentage}
            strokeColor={colorStroke}
            showInfo={false}
            className="progressMain"
          />
          {allQuotaAvail && (
            <Flex justify="space-between">
              <Flex gap={4}>
                <span className="font-medium text-2xsC">Basic: </span>
                <span
                  className={`font-medium text-2xsC text-secondary ${
                    basicQuotaAvail < 25
                      ? "text-danger"
                      : basicQuotaAvail <= 50
                      ? "text-warning"
                      : ""
                  }`}
                >
                  {formatNumberWithDot(basicQuotaAvail)}
                </span>
              </Flex>
              <Flex gap={4}>
                <span className="font-medium text-2xsC">Advanced: </span>
                <span
                  className={`font-medium text-2xsC text-secondary ${
                    advancedQuotaAvail < 25
                      ? "text-danger"
                      : advancedQuotaAvail <= 50
                      ? "text-warning"
                      : ""
                  }`}
                >
                  {formatNumberWithDot(advancedQuotaAvail)}
                </span>
              </Flex>
            </Flex>
          )}
        </>
      )}
    </>
  );
};

const EmailValidationSummary = ({
  statisticData,
  isClaimQuota,
  propsUser,
  companyUUID,
}) => {
  return (
    <>
      <Col xl={6} lg={12} md={12} sm={12} xs={24}>
        <div className="px-6 py-4 mr-6">
          <div className="flex justify-between items-center">
            <div className="text-secondary">Total Valid</div>
            <Tooltip
              overlayInnerStyle={{ width: "max-content" }}
              trigger={["click"]}
              title="Total email valid yang dapat dikirimkan email"
            >
              <InfoCircleOutlined className="text-secondary" />
            </Tooltip>
          </div>
          <Title level={2} className="mt-0" style={{ marginBottom: 0 }}>
            {formatNumberWithDot(statisticData?.totalValid)}
          </Title>
          <Text type="success" className="font-medium">
            Email Valid &#40;{statisticData?.totalValidPercent}%&#41;
          </Text>
          <Divider className="my-2" />
        </div>
      </Col>
      <Col xl={6} lg={12} md={12} sm={12} xs={24}>
        <div className="px-6 py-4 mr-6">
          <div className="flex justify-between items-center ">
            <div className="text-secondary">Total Invalid</div>
            <Tooltip
              overlayInnerStyle={{ width: "max-content" }}
              trigger={["click"]}
              title="Total email yang invalid dan tidak dapat dituju"
            >
              <InfoCircleOutlined className="text-secondary" />
            </Tooltip>
          </div>
          <Title level={2} className="mt-0" style={{ marginBottom: 0 }}>
            {formatNumberWithDot(statisticData?.totalInvalid)}
          </Title>
          <Text type="danger" className="font-medium">
            Email Invalid ({statisticData?.totalInvalidPercent}%)
          </Text>
          <Divider className="my-2" />
        </div>
      </Col>
      <Col xl={6} lg={12} md={12} sm={12} xs={24}>
        <div className="px-6 py-4 mr-6">
          <div className="flex justify-between items-center ">
            <div className="text-secondary">Total Email</div>
            <Tooltip
              overlayInnerStyle={{ width: "max-content" }}
              trigger={["click"]}
              title="Total seluruh email yang sudah dicek"
            >
              <InfoCircleOutlined className="text-secondary" />
            </Tooltip>
          </div>
          <Title level={2} className="mt-0" style={{ marginBottom: 0 }}>
            {formatNumberWithDot(
              statisticData?.totalValid + statisticData?.totalInvalid
            )}
          </Title>
          <Text className="font-medium" style={{ color: "#1890FF" }}>
            Email Checked
          </Text>
          <Divider className="my-2" />
        </div>
      </Col>
      <Col xl={6} lg={12} md={12} sm={12} xs={24}>
        <div className="px-6 py-4">
          <UsedQuota
            statisticData={statisticData}
            isClaimQuota={isClaimQuota}
            propsUser={propsUser}
            companyUUID={companyUUID}
          />
          <Divider className="my-2" />
        </div>
      </Col>
    </>
  );
};

export default EmailValidationSummary;
