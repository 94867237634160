import { Modal, Flex, Image } from "antd";
import { validateFileBulk } from "../../utils/helpers";
import "../modal/modal.less";
import { FormUploadBulk } from "../form/FormUploadBulk";
import processImg from "../../assets/images/processBulk.svg";
import succcesImg from "../../assets/images/successBulk.svg";
import errorImg from "../../assets/images/failedProcess.svg";
import Spinner from "../Spinner/Spinner";
import { uploadFileBulk } from "../../api";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

export const ModalUploadBulk = ({
  open,
  close,
  setListName,
  setFileBulk,
  listName,
  fileBulk,
  setFileValidation,
  fileValidation,
  form,
  setProcess,
  setSuccess,
  setError,
  process,
  success,
  error,
}) => {
  const onProcess = process && !success;
  const onSuccess = !process && success;
  const onError = !process && !success && error;
  const [responseData, setResponseData] = useState(null);
  const navigate = useNavigate();

  const handleUploadData = async () => {
    setProcess(true);
    const responseUpload = await uploadFileBulk({
      name: listName,
      file: fileBulk.originFileObj,
    });
    setTimeout(() => {
      if (responseUpload.statusCode === 200) {
        setResponseData(responseUpload?.data);
        setSuccess(true);
        setProcess(false);
      } else if (responseUpload.statusCode === 400) {
        setResponseData(responseUpload);
        setError(true);
        setProcess(false);
      } else {
        setError(true);
        setProcess(false);
      }
    }, 2000);
  };

  const props = {
    customRequest: ({ file, onSuccess, onError }) => {
      const result = validateFileBulk({ file });

      if (result) {
        onSuccess("ok");
      } else {
        onError(
          "File is not valid. Please upload a valid CSV or TXT file with size <= 200KB"
        );
      }
    },
    multiple: false,
    maxCount: 1,
  };

  return (
    <Modal
      key={onSuccess || onError}
      open={open}
      centered
      title={!(onProcess || onSuccess || onError) && "Upload List Email"}
      okText={
        onSuccess
          ? "Mulai verifikasi"
          : onError
          ? responseData?.statusCode === 400
            ? "Oke"
            : "Kontak Support"
          : "Upload Data"
      }
      cancelText={
        onSuccess || onError
          ? responseData?.statusCode === 400
            ? responseData?.msg === "Failed when reading the CSV file"
              ? "Kontak Support"
              : "Tambah Kuota"
            : "Tutup"
          : "Batal"
      }
      onCancel={() => {
        if (onSuccess || onError) {
          if (responseData?.statusCode === 400) {
            if (responseData?.msg === "Failed when reading the CSV file") {
              window.open("https://support.aktiva.co.id", "_blank");
              close();
            } else {
              window.open(
                "https://www.aktiva.co.id/email-validator-order/",
                "_blank"
              );
              close();
            }
          } else {
            close((onSuccess || onError) && "afterProcess");
          }
        } else {
          close();
        }
      }}
      onOk={
        onSuccess
          ? () => navigate(`/bulk-validation/${responseData?.uuid}`)
          : onError
          ? responseData?.statusCode === 400
            ? () => close()
            : () => (window.location.href = "https://support.aktiva.co.id")
          : handleUploadData
      }
      width={onProcess || onSuccess ? 540 : 572}
      {...(onProcess && { footer: false })}
      okButtonProps={{
        disabled: !(listName && fileBulk && fileValidation),
      }}
      closeIcon={onProcess || onSuccess || onError ? false : true}
      {...((onProcess || onSuccess || onError) && {
        className: "validate-modal",
      })}
    >
      {onProcess || onSuccess || onError ? (
        <Flex justify="center" vertical gap={8}>
          <Image
            src={
              onProcess
                ? processImg
                : onSuccess
                ? succcesImg
                : onError
                ? errorImg
                : undefined
            }
            alt=""
            preview={false}
          />
          <h5 className="mt-0 mb-0 text-center text-base font-medium">
            <span {...(onProcess && { className: "pr-2" })}>
              {onProcess
                ? "Mengupload file anda"
                : onSuccess
                ? "File berhasil diupload"
                : onError
                ? "File gagal diupload"
                : undefined}
            </span>
            {onProcess && <Spinner />}
          </h5>
          <span className="text-sm text-center text-secondary pb-2">
            {onProcess ? (
              "Proses upload data anda kedalam sistem, harap tunggu hingga proses selesai. Jangan tutup halaman ini."
            ) : onSuccess ? (
              "Data list email anda berhasil diupload pada sistem kami. Selanjutnya anda dapat melakukan verifikasi daftar email tersebut."
            ) : onError ? (
              responseData?.statusCode === 400 ? (
                responseData?.msg === "Failed when reading the CSV file" ? (
                  <span>
                    File gagal diupload karena menggunakan format yang tidak
                    sesuai. Pastikan
                    <br /> terdapat table header pada isian file tersebut.
                  </span>
                ) : (
                  <span>
                    File gagal diupload karena kuota validasi email anda tidak
                    mencukupi.
                    <br /> Silakan dapat melakukan penambahan kuota.
                  </span>
                )
              ) : (
                "File tersebut gagal diupload pada sistem kami, terdapat kesalahan saat mengupload. Harap coba lagi atau hubungi Tim Support kami."
              )
            ) : undefined}
          </span>
        </Flex>
      ) : (
        <FormUploadBulk
          setListName={setListName}
          setFileBulk={setFileBulk}
          setFileValidation={setFileValidation}
          props={props}
          form={form}
        />
      )}
    </Modal>
  );
};
