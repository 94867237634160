import {
  Table,
  Button,
  Grid,
  Collapse,
  Typography,
  Form,
  Flex,
  Skeleton,
} from "antd";
import { TableTitle } from "../../table-title/TableTitle";
import { EmptyApiKey } from "../../empty/EmptyComponent";
import {
  ICDeleteOutlined,
  ICUpOutlined,
  ICEyeInvisibleOutlined,
  ICEyeOutlined,
  ICCopyOutlined,
  ICCheckOutlined,
} from "../../list/ListIcon";
import { useState } from "react";
import { btnCopyKey } from "../../../utils/helpers";
import { ModalAddApiKey } from "../../modal/ModalAddApiKey";
import { ModalDeleteKey } from "../../modal/ModalDelete";

const { Text } = Typography;
const { Panel } = Collapse;
const { useBreakpoint } = Grid;

export const TableApiKey = ({ propsList }) => {
  const pwdHide = <span>*******************</span>;
  const [showModalKey, setShowModalKey] = useState(false);
  const [openModalDeleteKey, setOpenModalDeleteKey] = useState(false);
  const [nameValue, setNameValue] = useState("");
  const [descValue, setDescValue] = useState("");
  const [rowValue, setRowValue] = useState("");
  const [form] = Form.useForm();
  const screens = useBreakpoint();
  const LargeScreen = screens.lg;
  const [keyStates, setKeyStates] = useState({});

  const { dataRow, statusCode, listApiKey, getListApiKey, loading } = propsList;

  const toggleVisibility = (key) => {
    setKeyStates((prev) => ({
      ...prev,
      [key]: {
        ...prev[key],
        hideToken: !prev[key]?.hideToken,
      },
    }));
  };

  const toggleCopyState = (key) => {
    setKeyStates((prev) => ({
      ...prev,
      [key]: {
        ...prev[key],
        copyToken: !prev[key]?.copyToken,
      },
    }));
  };

  const columnAPI = [
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdDate",
      ...(LargeScreen && { width: 120 }),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ...(LargeScreen && { width: 200 }),
    },
    {
      title: "API Key",
      dataIndex: "apiKey",
      key: "apiKey",
      ...(LargeScreen && { width: 300 }),
      render: (values, record) => (
        <Text>
          {!keyStates[record.apiKey]?.hideToken ? pwdHide : values}
          <Button
            className="m-0 text-secondary"
            type="link"
            icon={
              !keyStates[record.apiKey]?.hideToken ? (
                <ICEyeOutlined className="text-sm" />
              ) : (
                <ICEyeInvisibleOutlined className="text-sm" />
              )
            }
            onClick={() => toggleVisibility(record.apiKey)}
          />
          <Button
            className="m-0 text-secondary"
            type="link"
            icon={
              !keyStates[record.apiKey]?.copyToken ? (
                <ICCopyOutlined className="text-sm" />
              ) : (
                <ICCheckOutlined className="text-sm" />
              )
            }
            onClick={() => btnCopyKey(values, record.apiKey, toggleCopyState)}
          />
        </Text>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
  ];

  const openModalKey = () => {
    setShowModalKey(true);
  };

  const closeModal = ({ process }) => {
    setNameValue("");
    setDescValue("");
    form.resetFields();
    setShowModalKey(false);
    if (process === "success") {
      getListApiKey();
    }
  };

  const TitleAccordion = ({ title }) => {
    return (
      <div className="flex items-center gap-x-4">
        <span className="font-medium text-sm">{title}</span>
      </div>
    );
  };

  const CustomHeader = ({ rotate, item }) => {
    const [click, setClick] = useState(rotate);

    const handleDeleteClick = (item) => {
      setRowValue(item);
      setOpenModalDeleteKey(true);
    };

    return (
      <Flex gap={8}>
        <Button
          icon={<ICDeleteOutlined />}
          danger
          type="link"
          onClick={(e) => {
            handleDeleteClick(item);
            e.stopPropagation();
          }}
        >
          Delete
        </Button>
        <Button
          type="link"
          className="flex items-center text-success"
          onClick={() => setClick((prev) => !prev)}
        >
          Show details
          <ICUpOutlined className="icon-key text-xs" rotate={click ? 0 : 180} />
        </Button>
      </Flex>
    );
  };

  const props = {
    nameValue,
    setNameValue,
    descValue,
    setDescValue,
    closeModal,
    showModalKey,
  };

  return (
    <div className="flex flex-col">
      <TableTitle
        title="API Key Details"
        description="Buat API Key dan hubungkan dengan aplikasi milik anda untuk melakukan verifikasi email"
        dataJSON={listApiKey}
        props={props}
        openModalKey={openModalKey}
      />
      {statusCode === 200 ? (
        <>
          {loading ? (
            <Skeleton active />
          ) : (
            <Collapse ghost className="header-key">
              {dataRow.map((item, index) => (
                <Panel
                  key={index}
                  header={<CustomHeader rotate={false} item={item} />}
                  extra={<TitleAccordion title={item.name} />}
                  showArrow={false}
                  className="panel-key"
                  collapsible="header"
                >
                  <Table
                    columns={columnAPI}
                    dataSource={dataRow.filter((row) => row.uuid === item.uuid)}
                    pagination={false}
                  />
                </Panel>
              ))}
            </Collapse>
          )}
          <ModalDeleteKey
            open={openModalDeleteKey}
            setOpen={setOpenModalDeleteKey}
            form={form}
            rowValue={rowValue}
            getListApiKey={getListApiKey}
          />
        </>
      ) : (
        <EmptyApiKey
          title="Data belum tersedia"
          message="Anda belum memiliki API key. Silakan buat API Key anda untuk melakukan integrasi dengan sistem"
        />
      )}
      <ModalAddApiKey props={props} form={form} />
    </div>
  );
};
