import { Typography, Flex, Row, Tag, Tooltip, Col, Alert } from "antd";
import logoDark from "../assets/images/aktiva-dark.png";
import logo from "../assets/images/aktiva.png";
import {
  InfoCircleOutlined,
  CloseCircleFilled,
  CheckCircleFilled,
} from "@ant-design/icons";
import CheckCircle from "../assets/images/check-circle.svg";
import CloseCircle from "../assets/images/close-circle.svg";

const { Title, Text } = Typography;

export const LogoAktiva = ({ className }) => {
  return (
    <div className={className}>
      <img src={logo} alt="logo" />
    </div>
  );
};

export const LogoAktivaDark = ({ className }) => {
  return (
    <div className={className}>
      <img src={logoDark} alt="logo" />
    </div>
  );
};

export const TitleAndParagraph = ({
  level,
  classNameT,
  classNameP,
  childrenP,
  childrenT,
}) => {
  return (
    <>
      <Title level={level} className={`${classNameT} title-form`}>
        {childrenT}
      </Title>
      <p className={`text-secondary ${classNameP}`}>{childrenP}</p>
    </>
  );
};

export const TitleParagraph = ({
  title = "",
  desc = "",
  descClass,
  className,
}) => {
  const titleClass = className || "mb-1";
  return (
    <>
      <h2 className={`text-3xl font-medium ${titleClass}`}>{title}</h2>
      <p className={`text-secondary ${descClass}`}>{desc}</p>
    </>
  );
};

export const AlertResult = ({ email, propsState, type }) => {
  const {
    validPotential,
    serverUnknown,
    domainInvalid,
    disposable,
    maybeSave,
    maybeInvalid,
  } = propsState;

  return (
    <Alert
      className="result-alert"
      message={
        <Text className="font-medium text-base">
          {email} {}
          {validPotential
            ? type === "basic"
              ? "berpotensi valid"
              : "valid"
            : maybeSave || (maybeInvalid && type === "basic")
            ? "mungkin tidak valid"
            : "tidak valid"}
        </Text>
      }
      description={
        <Text className="text-xs text-secondary">
          {validPotential
            ? type === "basic"
              ? "Alamat email berpotensi valid dan terdaftar, domain & server email valid"
              : "Alamat email valid dan terdaftar, dapat menerima email"
            : serverUnknown
            ? "Server email domain tersebut tidak dapat dijangkau"
            : domainInvalid
            ? "Domain yang digunakan tidak valid dan tidak terdaftar di internet"
            : disposable
            ? "Email tersebut terdeteksi email sekali pakai dan berpotensi tidak valid"
            : maybeSave
            ? "Server email tujuan menerima semua alamat email, berpotensi tidak valid"
            : maybeInvalid && type === "basic"
            ? "Alamat email tujuan merupakan role-based email, tidak bersifat personal"
            : "Alamat email salah atau tidak terdaftar, tidak dapat menerima email "}
        </Text>
      }
      type={
        validPotential
          ? "success"
          : maybeSave || (maybeInvalid && type === "basic")
          ? "warning"
          : "error"
      }
      showIcon={
        validPotential ? (
          <img src={CheckCircle} alt="" />
        ) : (
          <img src={CloseCircle} alt="" />
        )
      }
    />
  );
};

export const DetailResultAdvancedComp = ({
  emailType,
  domainStatus,
  serverStatus,
  emailFormat,
  openRelay,
  detailModal,
  disposableEmail,
  valid,
  roleEmail,
}) => {
  return (
    <>
      <Row gutter={24}>
        <Col span={12}>
          <Row justify="space-between" className="pb-4">
            <Flex gap={4}>
              <p className="mt-0 mb-0 font-medium">Deliver Email?</p>
              {!detailModal && (
                <Tooltip
                  title={
                    valid === "valid"
                      ? "Alamat email tujuan valid dan sesuai, dapat menerima email"
                      : valid === "risky"
                      ? "Kemungkinan email tidak valid karena tidak dapat divalidasi di server tujuan"
                      : "Alamat email tujuan invalid, kemungkinan tidak terdaftar atau salah ketik"
                  }
                  placement="bottom"
                >
                  <InfoCircleOutlined className="text-secondary" />
                </Tooltip>
              )}
            </Flex>
            <Tag className="mr-0 font-medium">
              {valid === "valid" ? (
                <span style={{ color: "#1890FF" }}>Yes, Valid</span>
              ) : valid === "risky" ? (
                <span style={{ color: "#FA8C16" }}>Maybe Valid</span>
              ) : (
                <span style={{ color: "#F5222D" }}>No, Invalid</span>
              )}
            </Tag>
            {detailModal && (
              <Text className="text-secondary text-xs py-1">
                {valid === "valid"
                  ? "Alamat email tujuan valid dan sesuai, dapat menerima email"
                  : valid === "risky"
                  ? "Kemungkinan email tidak valid karena tidak dapat divalidasi di server tujuan"
                  : "Alamat email tujuan invalid, kemungkinan tidak terdaftar atau salah ketik"}
              </Text>
            )}
          </Row>
          <Row justify="space-between" className="pb-4">
            <Flex gap={4}>
              <p className="mt-0 mb-0 font-medium">Email Type</p>
              {!detailModal && (
                <Tooltip
                  title={
                    emailType === "Profesional"
                      ? "Alamat email menggunakan custom domain "
                      : "Alamat email layanan email gratis yang ada di internet"
                  }
                  placement="bottom"
                >
                  <InfoCircleOutlined className="text-secondary" />
                </Tooltip>
              )}
            </Flex>
            <Tag className="mr-0 font-medium">
              {emailType === "Profesional" ? (
                "Profesional"
              ) : (
                <span style={{ color: "#FA8C16" }}>Free Email</span>
              )}
            </Tag>
            {detailModal && (
              <Text className="text-secondary text-xs py-1">
                {emailType === "Profesional"
                  ? "Menggunakan custom domain dengan email alamat email yang professional."
                  : "Menggunakan layanan email gratis yang ada di internet."}
              </Text>
            )}
          </Row>
          <Row justify="space-between" className="pb-4">
            <Flex gap={4}>
              <p className="mt-0 mb-0 font-medium">Domain Status</p>
              {!detailModal && (
                <Tooltip
                  title={
                    domainStatus === true
                      ? "Domain yang digunakan terdaftar di internet"
                      : "Domain tidak terdaftar pada internet atau tidak memiliki DNS"
                  }
                  placement="bottom"
                >
                  <InfoCircleOutlined className="text-secondary" />
                </Tooltip>
              )}
            </Flex>
            <Tag className="mr-0">
              {domainStatus === true ? (
                <div className="font-medium" style={{ color: "#1890FF" }}>
                  <CheckCircleFilled />
                  <span className="ml-2">Valid</span>
                </div>
              ) : (
                <div className="font-medium" style={{ color: "#F5222D" }}>
                  <CloseCircleFilled />
                  <span className="ml-2">Invalid</span>
                </div>
              )}
            </Tag>
            {detailModal && (
              <Text className="text-secondary text-xs py-1">
                {domainStatus
                  ? "Domain yang digunakan valid dan terdaftar di internet."
                  : "Domain tujuan tidak tidak terdaftar di internet dan tidak valid."}
              </Text>
            )}
          </Row>
          <Row justify="space-between">
            <Flex gap={4}>
              <p className="mt-0 mb-0 font-medium">Disposable Email</p>
              {!detailModal && (
                <Tooltip
                  title={
                    disposableEmail
                      ? "Alamat email merupakan email sekali pakai dan berpotensi tidak valid"
                      : "Alamat email bukan email sekali pakai dan aman untuk dikirimkan"
                  }
                  placement="bottom"
                >
                  <InfoCircleOutlined className="text-secondary" />
                </Tooltip>
              )}
            </Flex>
            <Tag className="mr-0">
              {disposableEmail ? (
                <div className="font-medium" style={{ color: "#F5222D" }}>
                  <span>Yes</span>
                </div>
              ) : (
                <div className="font-medium">
                  <span>No</span>
                </div>
              )}
            </Tag>
            {detailModal && (
              <Text className="text-secondary text-xs py-1">
                {disposableEmail
                  ? "Alamat email merupakan email sekali pakai dan berpotensi tidak valid"
                  : "Alamat email bukan email sekali pakai dan aman untuk dikirimkan"}
              </Text>
            )}
          </Row>
        </Col>
        <Col span={12}>
          <Row justify="space-between" className="pb-4">
            <Flex gap={4}>
              <p className="mt-0 mb-0 font-medium">Accept All</p>
              {!detailModal && (
                <Tooltip
                  title={
                    openRelay
                      ? "Email server tujuan menerima semua email tanpa ada filter email yang valid"
                      : "Email server tujuan hanya menerima email yang valid"
                  }
                  placement="bottom"
                >
                  <InfoCircleOutlined className="text-secondary" />
                </Tooltip>
              )}
            </Flex>
            <Tag className="mr-0">
              {openRelay ? (
                <div className="font-medium" style={{ color: "#F5222D" }}>
                  <span>Yes</span>
                </div>
              ) : (
                <div className="font-medium">
                  <span>No</span>
                </div>
              )}
            </Tag>
            {detailModal && (
              <Text className="text-secondary text-xs py-1">
                {openRelay
                  ? "Email server tujuan menerima semua email tanpa ada filter alamat email yang valid."
                  : "Email server tujuan hanya menerima email yang valid dan sesuai."}
              </Text>
            )}
          </Row>

          <Row justify="space-between" className="pb-4">
            <Flex gap={4}>
              <p className="mt-0 mb-0 font-medium">Server Status</p>
              {!detailModal && (
                <Tooltip
                  title={
                    serverStatus === true
                      ? "Email server penerima valid dan dapat terhubung"
                      : "Email server penerima tidak valid dan tidak dapat menerima email"
                  }
                  placement="bottom"
                >
                  <InfoCircleOutlined className="text-secondary" />
                </Tooltip>
              )}
            </Flex>
            <Tag className="mr-0">
              {serverStatus === true ? (
                <div className="font-medium" style={{ color: "#1890FF" }}>
                  <CheckCircleFilled />
                  <span className="ml-2">Valid</span>
                </div>
              ) : (
                <div className="font-medium" style={{ color: "#F5222D" }}>
                  <CloseCircleFilled />
                  <span className="ml-2">Invalid</span>
                </div>
              )}
            </Tag>
            {detailModal && (
              <Text className="text-secondary text-xs py-1">
                {serverStatus
                  ? "Email server penerima valid dan dapat terhubung & menerima email."
                  : "Email server penerima tidak valid dan tidak dapat menerima email."}
              </Text>
            )}
          </Row>
          <Row justify="space-between" className="pb-4">
            <Flex gap={4}>
              <p className="mt-0 mb-0 font-medium">Email Format</p>
              {!detailModal && (
                <Tooltip
                  title={
                    emailFormat === true
                      ? "Format email valid dan sesuai dengan ketentuan"
                      : "Format email tidak sesuai dengan ketentuan"
                  }
                  placement="bottom"
                >
                  <InfoCircleOutlined className="text-secondary" />
                </Tooltip>
              )}
            </Flex>
            <Tag className="mr-0">
              {emailFormat === true ? (
                <div className="font-medium" style={{ color: "#1890FF" }}>
                  <CheckCircleFilled />
                  <span className="ml-2">Valid</span>
                </div>
              ) : (
                <div className="font-medium" style={{ color: "#F5222D" }}>
                  <CloseCircleFilled />
                  <span className="ml-2">Invalid</span>
                </div>
              )}
            </Tag>
            {detailModal && (
              <Text className="text-secondary text-xs py-1">
                {emailFormat
                  ? "Format alamat email valid dan sesuai dengan ketentuan."
                  : "Format alamat email salah dan tidak sesuai dengan ketentuan."}
              </Text>
            )}
          </Row>

          <Row justify="space-between">
            <Flex gap={4}>
              <p className="mt-0 mb-0 font-medium">Role Email</p>
              {!detailModal && (
                <Tooltip
                  title={
                    roleEmail
                      ? "Alamat email merupakan role-based email, kurang direkomendasikan"
                      : "Alamat email bukan merupakan role-based email dan bersifat personal"
                  }
                  placement="bottom"
                >
                  <InfoCircleOutlined className="text-secondary" />
                </Tooltip>
              )}
            </Flex>
            <Tag className="mr-0">
              {roleEmail ? (
                <div className="font-medium" style={{ color: "#F5222D" }}>
                  <span>Yes</span>
                </div>
              ) : (
                <div className="font-medium">
                  <span>No</span>
                </div>
              )}
            </Tag>
            {detailModal && (
              <Text className="text-secondary text-xs py-1">
                {roleEmail
                  ? "Alamat email merupakan role-based email, kurang direkomendasikan."
                  : "Alamat email bukan merupakan role-based email dan bersifat personal."}
              </Text>
            )}
          </Row>
        </Col>
      </Row>
    </>
  );
};

export const DetailResultBasicComponent = ({
  emailType,
  domainStatus,
  serverStatus,
  emailFormat,
  detailModal,
  disposableEmail,
  roleEmail,
}) => {
  return (
    <>
      <Row gutter={24}>
        <Col span={12}>
          <Row justify="space-between" className="pb-4">
            <Flex gap={4}>
              <p className="mt-0 mb-0 font-medium">Email Type</p>
              {!detailModal && (
                <Tooltip
                  title={
                    emailType === "Profesional"
                      ? "Alamat email menggunakan custom domain "
                      : "Alamat email layanan email gratis yang ada di internet"
                  }
                  placement="bottom"
                >
                  <InfoCircleOutlined className="text-secondary" />
                </Tooltip>
              )}
            </Flex>
            <Tag className="mr-0 font-medium">
              {emailType === "Profesional" ? (
                "Profesional"
              ) : (
                <span style={{ color: "#FA8C16" }}>Free Email</span>
              )}
            </Tag>
            {detailModal && (
              <Text className="text-secondary text-xs py-1">
                {emailType === "Profesional"
                  ? "Menggunakan custom domain dengan email alamat email yang professional."
                  : "Menggunakan layanan email gratis yang ada di internet."}
              </Text>
            )}
          </Row>
          <Row justify="space-between" className="pb-4">
            <Flex gap={4}>
              <p className="mt-0 mb-0 font-medium">Domain Status</p>
              {!detailModal && (
                <Tooltip
                  title={
                    domainStatus === true
                      ? "Domain yang digunakan terdaftar di internet"
                      : "Domain tidak terdaftar pada internet atau tidak memiliki DNS"
                  }
                  placement="bottom"
                >
                  <InfoCircleOutlined className="text-secondary" />
                </Tooltip>
              )}
            </Flex>
            <Tag className="mr-0">
              {domainStatus === true ? (
                <div className="font-medium" style={{ color: "#1890FF" }}>
                  <CheckCircleFilled />
                  <span className="ml-2">Valid</span>
                </div>
              ) : (
                <div className="font-medium" style={{ color: "#F5222D" }}>
                  <CloseCircleFilled />
                  <span className="ml-2">Invalid</span>
                </div>
              )}
            </Tag>
            {detailModal && (
              <Text className="text-secondary text-xs py-1">
                {domainStatus
                  ? "Domain yang digunakan valid dan terdaftar di internet."
                  : "Domain tujuan tidak tidak terdaftar di internet dan tidak valid."}
              </Text>
            )}
          </Row>
          <Row justify="space-between">
            <Flex gap={4}>
              <p className="mt-0 mb-0 font-medium">Disposable Email</p>
              {!detailModal && (
                <Tooltip
                  title={
                    disposableEmail
                      ? "Alamat email merupakan email sekali pakai dan berpotensi tidak valid"
                      : "Alamat email bukan email sekali pakai dan aman untuk dikirimkan"
                  }
                  placement="bottom"
                >
                  <InfoCircleOutlined className="text-secondary" />
                </Tooltip>
              )}
            </Flex>
            <Tag className="mr-0">
              {disposableEmail ? (
                <div className="font-medium" style={{ color: "#F5222D" }}>
                  <span>Yes</span>
                </div>
              ) : (
                <div className="font-medium">
                  <span>No</span>
                </div>
              )}
            </Tag>
            {detailModal && (
              <Text className="text-secondary text-xs py-1">
                {disposableEmail
                  ? "Alamat email merupakan email sekali pakai dan berpotensi tidak valid"
                  : "Alamat email bukan email sekali pakai dan aman untuk dikirimkan"}
              </Text>
            )}
          </Row>
        </Col>
        <Col span={12}>
          <Row justify="space-between" className="pb-4">
            <Flex gap={4}>
              <p className="mt-0 mb-0 font-medium">Server Status</p>
              {!detailModal && (
                <Tooltip
                  title={
                    serverStatus === true
                      ? "Email server penerima valid dan dapat terhubung"
                      : "Email server penerima tidak valid dan tidak dapat menerima email"
                  }
                  placement="bottom"
                >
                  <InfoCircleOutlined className="text-secondary" />
                </Tooltip>
              )}
            </Flex>
            <Tag className="mr-0">
              {serverStatus === true ? (
                <div className="font-medium" style={{ color: "#1890FF" }}>
                  <CheckCircleFilled />
                  <span className="ml-2">Valid</span>
                </div>
              ) : (
                <div className="font-medium" style={{ color: "#F5222D" }}>
                  <CloseCircleFilled />
                  <span className="ml-2">Invalid</span>
                </div>
              )}
            </Tag>
            {detailModal && (
              <Text className="text-secondary text-xs py-1">
                {serverStatus
                  ? "Email server penerima valid dan dapat terhubung & menerima email."
                  : "Email server penerima tidak valid dan tidak dapat menerima email."}
              </Text>
            )}
          </Row>
          <Row justify="space-between" className="pb-4">
            <Flex gap={4}>
              <p className="mt-0 mb-0 font-medium">Email Format</p>
              {!detailModal && (
                <Tooltip
                  title={
                    emailFormat === true
                      ? "Format email valid dan sesuai dengan ketentuan"
                      : "Format email tidak sesuai dengan ketentuan"
                  }
                  placement="bottom"
                >
                  <InfoCircleOutlined className="text-secondary" />
                </Tooltip>
              )}
            </Flex>
            <Tag className="mr-0">
              {emailFormat === true ? (
                <div className="font-medium" style={{ color: "#1890FF" }}>
                  <CheckCircleFilled />
                  <span className="ml-2">Valid</span>
                </div>
              ) : (
                <div className="font-medium" style={{ color: "#F5222D" }}>
                  <CloseCircleFilled />
                  <span className="ml-2">Invalid</span>
                </div>
              )}
            </Tag>
            {detailModal && (
              <Text className="text-secondary text-xs py-1">
                {emailFormat
                  ? "Format alamat email valid dan sesuai dengan ketentuan."
                  : "Format alamat email salah dan tidak sesuai dengan ketentuan."}
              </Text>
            )}
          </Row>
          <Row justify="space-between">
            <Flex gap={4}>
              <p className="mt-0 mb-0 font-medium">Role Email</p>
              {!detailModal && (
                <Tooltip
                  title={
                    roleEmail
                      ? "Alamat email merupakan role-based email, kurang direkomendasikan"
                      : "Alamat email bukan merupakan role-based email dan bersifat personal"
                  }
                  placement="bottom"
                >
                  <InfoCircleOutlined className="text-secondary" />
                </Tooltip>
              )}
            </Flex>
            <Tag className="mr-0">
              {roleEmail ? (
                <div className="font-medium" style={{ color: "#F5222D" }}>
                  <span>Yes</span>
                </div>
              ) : (
                <div className="font-medium">
                  <span>No</span>
                </div>
              )}
            </Tag>
            {detailModal && (
              <Text className="text-secondary text-xs py-1">
                {roleEmail
                  ? "Alamat email merupakan role-based email, kurang direkomendasikan."
                  : "Alamat email bukan merupakan role-based email dan bersifat personal."}
              </Text>
            )}
          </Row>
        </Col>
      </Row>
    </>
  );
};
