import fetch from "@apicase/adapter-fetch";
import { ApiService, ApiTree } from "@apicase/services";
import {
  getCookie,
  getSessionToken,
  setLogout,
  setCookieToken,
} from "../utils/helpers";
import apiList from "./list";

// VARIABLE LIST
// make sure match for your APP
const appBaseUrl = process.env.REACT_APP_BE_BASE_URL; // Get base url from environment variable
const urlGetToken = "api/v1/user/login";
const urlRefreshToken = "api/v1/user/login-refresh";

const RootService = new ApiService({
  adapter: fetch,
  url: appBaseUrl,
  mode: "cors",
  headers: {
    "Content-Type": "application/json; charset=utf-8",
  },
  options: { timeout: 1000 },
  hooks: {
    before({ payload, next }) {
      payload.headers = {
        ...payload.headers,
      };
      next(payload);
    },
  },
});

const getCookieToken = () => {
  return getCookie("token_validation");
};

const getCookieRefreshToken = () => {
  return getCookie("token_validation_refresh");
};

// GET  TOKEN API & SERVICE
const TokenService = (url = urlGetToken) =>
  RootService.extend({
    url,
    method: "POST",
    body: {
      authToken: getCookieToken(),
      refreshToken: getCookieRefreshToken(),
    },

    hooks: {
      before({ payload, next }) {
        const token = getCookieRefreshToken();
        const newPayload = { ...payload };
        newPayload.headers = {
          ...payload.headers,
          Authorization: `Bearer ${token}`,
        };
        next(newPayload);
      },
    },
  }).on("done", (result) => {
    const { authToken } = result.body.data;
    setCookieToken(authToken);
  });
// END OF GET TOKEN API & SERVICE

// const GetToken = TokenService();
const RefreshToken = TokenService(urlRefreshToken);

//  HIT TOKEN ACTIVITY
const hitToken = async (payload, retry, next, urlToken = urlGetToken) => {
  let fn = RefreshToken;
  const { success, result } = await fn.doSingleRequest();
  if (success) {
    const { authToken } = result.body.data;
    const newPayload = { ...payload };
    newPayload.headers = {
      ...payload.headers,
      Authorization: `Bearer ${authToken}`,
    };
    retry(newPayload);
    next(result);
  } else {
    failActivity(result.status, payload, retry, result, next);
  }
};
// END OF HIT TOKEN ACTIVITY

// FAIL API ACTIVITY
const failActivity = async (errorCode, payload, retry, result, next) => {
  if (errorCode === 401) {
    await hitToken(payload, retry, next, urlRefreshToken);
  } else if (errorCode === 406) setLogout();
};

const MainService = new ApiTree(RootService, [
  {
    url: "api/v1", // Base on API structure
    children: apiList,
    hooks: {
      before({ payload, next }) {
        const token = getSessionToken() || getCookieToken();
        const newPayload = { ...payload };
        newPayload.headers = {
          ...payload.headers,
          Authorization: `Bearer ${token}`,
        };
        next(newPayload);
      },
      async fail({ payload, retry, result, next }) {
        const errorCode = result.status;
        await failActivity(errorCode, payload, retry, result, next);
        next(result);
      },
      async done({ result, fail, next }) {
        next(result);
      },
    },
  },
]);

export default MainService;
