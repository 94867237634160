import { getUser } from "../../api";
import { useCallback, useEffect } from "react";
import { useJwt } from "react-jwt";
import { useDispatch, useSelector } from "react-redux";
import { setUserData } from "../../redux/slices/user";
import { getCookieToken, getSessionToken } from "../helpers";

const token = getSessionToken() || getCookieToken();
const useGetUser = () => {
  const { decodedToken = "" } = useJwt(token);

  const dispatch = useDispatch();
  const action = setUserData;
  const user = useSelector(({ userData }) => userData.value);

  const doSet = useCallback(
    (data) => dispatch(action(data)),
    [dispatch, action]
  );

  useEffect(() => {
    const getDataUser = async () => {
      const { uuid } = decodedToken;
      if (uuid) {
        const { data, statusCode, msg } = await getUser({ uuid: uuid });
        if (data && Object.keys(data).length) doSet({ data, statusCode, msg });
      }
    };
    if (decodedToken && !user) getDataUser();
  }, [decodedToken, doSet, user]);

  const refreshUser = async () => {
    const { uuid } = decodedToken;
    if (uuid) {
      const { data, code, msg } = await getUser({ uuid: uuid });
      if (data && Object.keys(data).length) doSet({ data, code, msg });
    }
  };

  return { decodedToken, refreshUser };
};

export default useGetUser;
