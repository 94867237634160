import { Modal, Button, Divider } from "antd";
import {
  AlertResult,
  DetailResultBasicComponent,
  DetailResultAdvancedComp,
} from "../Components";

export const ModalDetail = ({ showModalDetail, closeModalDetail, result }) => {
  const {
    openRelay = result?.data?.detail?.openRelay,
    disposableEmail = result?.data?.detail?.disposableEmail,
    email = result?.data?.detail?.email,
    emailType = result?.data?.detail?.emailType,
    domainStatus = result?.data?.detail?.domainStatus,
    serverStatus = result?.data?.detail?.serverStatus,
    emailFormat = result?.data?.detail?.emailFormat,
    type = result?.data?.type,
    roleEmail = result?.data?.detail?.roleEmail,
    validationType = result?.data?.detail?.validationType,
    valid = result?.data?.valid,
  } = result;

  const validPotential =
    valid === "valid" &&
    !disposableEmail &&
    domainStatus &&
    serverStatus &&
    emailFormat &&
    (!roleEmail || (type === "basic" && !roleEmail));

  const serverUnknown =
    !disposableEmail && domainStatus && !serverStatus && emailFormat;

  const domainInvalid =
    !disposableEmail && !domainStatus && !serverStatus && emailFormat;

  const disposable =
    disposableEmail && domainStatus && serverStatus && emailFormat;

  const maybeSave =
    (type === "advance" ? valid === "risky" : "") &&
    openRelay &&
    !disposableEmail &&
    domainStatus &&
    serverStatus &&
    emailFormat;

  const maybeInvalid =
    !disposableEmail &&
    domainStatus &&
    serverStatus &&
    emailFormat &&
    roleEmail;

  const bouncedEmail =
    (type === "advance" ? valid === "invalid" : "") &&
    !openRelay &&
    !disposableEmail &&
    domainStatus &&
    serverStatus &&
    emailFormat;

  const propsState = {
    validPotential,
    serverUnknown,
    domainInvalid,
    disposable,
    maybeSave,
    bouncedEmail,
    maybeInvalid,
  };

  return (
    <Modal
      centered
      title="Detail Validation Email"
      open={showModalDetail}
      onCancel={closeModalDetail}
      width={572}
      footer={
        <Button onClick={closeModalDetail} className="mt-3">
          Tutup
        </Button>
      }
    >
      <AlertResult email={email} propsState={propsState} type={type} />
      <Divider className="mt-6 mb-5" />
      <div className="flex items-center justify-center w-full ">
        <h5 className="text-center text-base font-normal mb-7 mt-0">
          Details for <span className="font-medium">{email}</span>
        </h5>
      </div>
      {validationType === "basic" ? (
        <DetailResultBasicComponent
          detailModal={true}
          emailType={emailType}
          domainStatus={domainStatus}
          serverStatus={serverStatus}
          emailFormat={emailFormat}
          roleEmail={roleEmail}
          disposableEmail={disposableEmail}
        />
      ) : (
        <DetailResultAdvancedComp
          detailModal={true}
          emailType={emailType}
          domainStatus={domainStatus}
          serverStatus={serverStatus}
          emailFormat={emailFormat}
          openRelay={openRelay}
          disposableEmail={disposableEmail}
          valid={valid}
          roleEmail={roleEmail}
        />
      )}
    </Modal>
  );
};

export const ModalDetailList = ({
  showModalDetail,
  closeModalDetail,
  listRecord,
}) => {
  const {
    openRelay = listRecord.openRelay,
    email = listRecord.email,
    disposableEmail = listRecord.disposableEmail,
    emailType = listRecord.emailType,
    domainStatus = listRecord.domainStatus,
    serverStatus = listRecord.serverStatus,
    emailFormat = listRecord.emailFormat,
    type = listRecord.type,
    roleEmail = listRecord.roleEmail,
    valid = listRecord.valid,
  } = listRecord;

  const validPotential =
    valid === "valid" &&
    !disposableEmail &&
    domainStatus &&
    serverStatus &&
    emailFormat &&
    (!roleEmail || (type === "basic" && !roleEmail));

  const serverUnknown =
    !disposableEmail && domainStatus && !serverStatus && emailFormat;

  const domainInvalid =
    !disposableEmail && !domainStatus && !serverStatus && emailFormat;

  const disposable =
    disposableEmail && domainStatus && serverStatus && emailFormat;

  const maybeSave =
    (type === "advance" ? valid === "risky" : "") &&
    openRelay &&
    !disposableEmail &&
    domainStatus &&
    serverStatus &&
    emailFormat;

  const maybeInvalid =
    !disposableEmail &&
    domainStatus &&
    serverStatus &&
    emailFormat &&
    roleEmail;

  const bouncedEmail =
    (type === "advance" ? valid === "invalid" : "") &&
    !openRelay &&
    !disposableEmail &&
    domainStatus &&
    serverStatus &&
    emailFormat;

  const propsState = {
    validPotential,
    serverUnknown,
    domainInvalid,
    disposable,
    maybeSave,
    bouncedEmail,
    maybeInvalid,
  };

  return (
    <Modal
      centered
      title="Detail Validation Email"
      open={showModalDetail}
      onCancel={closeModalDetail}
      width={572}
      footer={
        <Button onClick={closeModalDetail} className="mt-3">
          Tutup
        </Button>
      }
    >
      <AlertResult email={email} propsState={propsState} type={type} />
      <Divider className="mt-6 mb-5" />
      <div className="flex items-center justify-center w-full ">
        <h5 className="text-center text-base font-normal mb-7 mt-0">
          Details for <span className="font-medium">{email}</span>
        </h5>
      </div>
      {type === "basic" ? (
        <DetailResultBasicComponent
          detailModal={true}
          emailType={emailType}
          domainStatus={domainStatus}
          serverStatus={serverStatus}
          emailFormat={emailFormat}
          roleEmail={roleEmail}
          disposableEmail={disposableEmail}
        />
      ) : (
        <DetailResultAdvancedComp
          detailModal={true}
          emailType={emailType}
          domainStatus={domainStatus}
          serverStatus={serverStatus}
          emailFormat={emailFormat}
          openRelay={openRelay}
          disposableEmail={disposableEmail}
          valid={valid}
          roleEmail={roleEmail}
        />
      )}
    </Modal>
  );
};
