import { Select, Input, Button, Row, Col, Grid, Tooltip } from "antd";
import { ReloadOutlined, ExportOutlined } from "@ant-design/icons";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { ICPlusOutlined } from "../list/ListIcon";
import { CSVLink } from "react-csv";
import { headerCSVBulk, headerCSVSingle } from "../headerCSV";

const { Search } = Input;
const { useBreakpoint } = Grid;
const { Option } = Select;

export const TableTitle = ({
  dataJSON,
  showSpinner,
  searchValue,
  handleSearch,
  changeSearchQuery,
  changePageLimit,
  getListValidate,
  title,
  description,
  refreshListBulk,
  openModalKey,
}) => {
  const [refresh, setRefresh] = useState(false);
  const screens = useBreakpoint();
  const isExtraLargeScreen = screens.xl;
  const isLargeScreen = screens.lg;
  const isMediumScreen = screens.md;
  const bigScreen = isExtraLargeScreen || isLargeScreen || isMediumScreen;
  const Location = useLocation();

  const singlePage = Location.pathname === "/single-validation";
  const bulkPage = Location.pathname === "/bulk-validation";
  const apiPage = Location.pathname === "/api-key";

  const BtnComponent = () => {
    return (
      <Button
        type="primary"
        {...(isExtraLargeScreen && (singlePage || bulkPage)
          ? { icon: <ExportOutlined /> }
          : bigScreen && apiPage
          ? {
              icon: <ICPlusOutlined />,
            }
          : undefined)}
        {...(!apiPage && {
          disabled: !(dataJSON || showSpinner) || dataJSON?.statusCode !== 200,
        })}
        {...(apiPage && { onClick: () => openModalKey() })}
      >
        {isExtraLargeScreen && (singlePage || bulkPage) ? (
          "Export To CSV"
        ) : bigScreen && apiPage ? (
          "Create API Key"
        ) : !isExtraLargeScreen && (singlePage || bulkPage) ? (
          <ExportOutlined />
        ) : !bigScreen && apiPage ? (
          <ICPlusOutlined />
        ) : undefined}
      </Button>
    );
  };

  return (
    <>
      <Row className="items-center pb-6 pt-2">
        <Col
          {...(!apiPage
            ? { xl: 12, lg: 12, md: 12, sm: 24, xs: 24 }
            : { xl: 18, lg: 18, md: 18, sm: 20, xs: 20 })}
        >
          <div className="title-text">
            <h5 className="text-base font-medium mb-0 mt-0">{title}</h5>
            <p className="text-sm text-secondary mb-0 mt-0">{description}</p>
          </div>
        </Col>
        <Col
          {...(!apiPage
            ? { xl: 12, lg: 12, md: 12, sm: 24, xs: 24 }
            : { xl: 6, lg: 6, md: 6, sm: 4, xs: 4 })}
          {...(isExtraLargeScreen || isLargeScreen
            ? {
                className: `flex ${apiPage && "flex-col items-end"} gap-x-2`,
              }
            : { className: `btn-filter ${apiPage && "flex-col items-end"}` })}
        >
          {!apiPage && (
            <Col className="flex gap-x-2 w-full">
              <Search
                placeholder={
                  singlePage
                    ? "Search email"
                    : bulkPage
                    ? "Search list"
                    : undefined
                }
                enterButton
                disabled={
                  !(dataJSON || showSpinner) || dataJSON?.statusCode !== 200
                }
                value={searchValue}
                onChange={(e) => handleSearch(e.target.value)}
                onSearch={changeSearchQuery}
              />
              <Select
                defaultValue="10"
                disabled={
                  !(dataJSON || showSpinner) ||
                  dataJSON?.data?.rows === null ||
                  (dataJSON?.data?.rows && dataJSON.data.rows.length === 0) ||
                  dataJSON?.statusCode !== 200
                }
                onChange={changePageLimit}
              >
                <Option value="10">10 per page</Option>
                <Option value="25">25 per page</Option>
                <Option value="50">50 per page</Option>
                <Option value="100">100 per page</Option>
              </Select>
            </Col>
          )}
          <Col
            {...(isExtraLargeScreen || isLargeScreen
              ? {
                  className: "flex gap-x-1",
                }
              : { className: "flex justify-end gap-x-1 gap-filter" })}
          >
            {!apiPage && (
              <Tooltip
                title={!isExtraLargeScreen ? "Refresh" : undefined}
                placement="top"
              >
                <Button
                  type="default"
                  {...(isExtraLargeScreen && {
                    icon: <ReloadOutlined />,
                    loading: refresh,
                  })}
                  {...(singlePage
                    ? {
                        onClick: async () => {
                          setRefresh(true);
                          await getListValidate();
                          setTimeout(() => setRefresh(false), 1500);
                        },
                      }
                    : bulkPage
                    ? {
                        onClick: async () => {
                          setRefresh(true);
                          await refreshListBulk();
                          setRefresh(false);
                        },
                      }
                    : undefined)}
                >
                  {isExtraLargeScreen ? (
                    "Refresh"
                  ) : (
                    <ReloadOutlined spin={refresh} />
                  )}
                </Button>
              </Tooltip>
            )}
            <Tooltip
              title={
                !isExtraLargeScreen && (singlePage || bulkPage)
                  ? "Export To CSV"
                  : !bigScreen && apiPage
                  ? "Create API Key"
                  : undefined
              }
              placement="top"
            >
              {dataJSON &&
              dataJSON.statusCode !== (404 || 500) &&
              ((isExtraLargeScreen && (singlePage || bulkPage)) ||
                (!isExtraLargeScreen && (singlePage || bulkPage))) ? (
                <CSVLink
                  data={
                    Array.isArray(dataJSON?.data?.rows)
                      ? dataJSON?.data?.rows
                      : []
                  }
                  headers={singlePage ? headerCSVSingle : headerCSVBulk}
                  filename={
                    singlePage
                      ? "Aktiva Single Validation Email History.csv"
                      : "Aktiva Bulk Validation Email History.csv"
                  }
                >
                  <BtnComponent />
                </CSVLink>
              ) : (
                <BtnComponent />
              )}
            </Tooltip>
          </Col>
        </Col>
      </Row>
    </>
  );
};
