import { TitleAndParagraph } from "../../../../components/Components";
import { Card, Button, Flex } from "antd";
import claimNow from "../../../../assets/images/claim-now.svg";
import exploreNow from "../../../../assets/images/explore_now.svg";
import { useState, useEffect } from "react";
import { claimFreeQuota, setupConfig, getUser } from "../../../../api";
import useDocumentTitle from "../../../../utils/useDocumentTitle";
import { setCookieToken } from "../../../../utils/helpers";

const ClaimQuota = ({
  next,
  title,
  afterVerif,
  setAfterVerif,
  companyUUID,
  getRefreshToken,
}) => {
  useDocumentTitle(title);
  const [getNow, setGetNow] = useState(false);
  const [expNow, setExpNow] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);

  const getTokenRefresh = async (refreshToken) => {
    const response = await fetch(
      `${process.env.REACT_APP_BE_BASE_URL}/api/v1/user/login-refresh`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${refreshToken}`,
        },
        body: JSON.stringify({}),
      }
    );

    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message || "Failed to refresh token");
    }

    return data;
  };

  useEffect(() => {
    const getNewData = async () => {
      const responseUser = await getUser();
      setAfterVerif(responseUser?.data);
    };
    if (!afterVerif) {
      getNewData();
    }
  }, [afterVerif, setAfterVerif, companyUUID]);

  const handleGetNow = async () => {
    setLoadingBtn(true);
    const { statusCode } = await claimFreeQuota({
      companyUuid:
        companyUUID !== "00000000-0000-0000-0000-000000000000"
          ? companyUUID
          : afterVerif?.companyUuid,
    });
    setLoadingBtn(false);
    if (statusCode === 200) {
      const newToken = await getTokenRefresh(getRefreshToken);
      const { statusCode, authToken = newToken?.data?.authToken } = newToken;
      if (statusCode === 200) {
        setCookieToken(authToken);
        next(getNow);
      }
    }
  };

  const handleExpNow = async () => {
    setLoadingBtn(true);
    const { statusCode } = await setupConfig({
      companyUuid:
        companyUUID !== "00000000-0000-0000-0000-000000000000"
          ? companyUUID
          : afterVerif?.companyUuid,
    });
    setLoadingBtn(false);
    if (statusCode === 200) {
      const newToken = await getTokenRefresh(getRefreshToken);
      const { statusCode, authToken = newToken?.data?.authToken } = newToken;
      if (statusCode === 200) {
        setCookieToken(authToken);
        next(getNow);
      }
    }
  };

  return (
    <>
      <div className="flex flex-col justify-start setup-form mx-auto mb-12">
        <h2 className="text-3xl mb-1 my-0 font-medium">Klaim Gratis Kuota</h2>
        <p className="text-secondary text-sm my-0">
          Klaim dan dapatkan 100 kuota verifikasi email secara gratis untuk
          melakukan verifikasi email customer anda. Verifikasi email akan
          memakan kredit dari kuota yang anda miliki.
        </p>
        <div className={`flex justify-center pt-4 gap-x-15`}>
          <div className="setup-now">
            <Card
              hoverable
              className={`card-setup ${getNow ? "card-setup-active" : ""}`}
              onClick={() => {
                setGetNow(true);
                setExpNow(false);
              }}
            >
              <TitleAndParagraph
                level={5}
                classNameT="header-5 mt-0"
                classNameP="mb-6"
                childrenP="Dapatkan 100 kuota validasi email gratis, untuk melakukan pengecekan email anda"
                childrenT="Klaim Gratis Kuota"
              />
              <div className="img-setup-card mx-auto svg-card">
                <img src={claimNow} alt="card-img" />
              </div>
            </Card>
          </div>
          <div className="setup-soon">
            <Card
              hoverable
              className={`card-setup ${expNow ? "card-setup-active" : ""}`}
              onClick={() => {
                setGetNow(false);
                setExpNow(true);
              }}
            >
              <TitleAndParagraph
                level={5}
                classNameT="header-5 mt-0"
                classNameP="mb-6"
                childrenP="Explore dashboard email validator dan lakukan klaim kuota gratis nanti"
                childrenT="Explore Dashboard"
              />
              <div className="img-setup-card mx-auto svg-card">
                <img src={exploreNow} alt="card-img" />
              </div>
            </Card>
          </div>
        </div>
        {(getNow || expNow) && (
          <Flex justify="center" className="pt-7">
            <Button
              type="primary"
              className="btn-setup"
              onClick={() => {
                getNow ? handleGetNow() : handleExpNow();
              }}
              loading={loadingBtn}
            >
              Selanjutnya
            </Button>
          </Flex>
        )}
      </div>
    </>
  );
};

export default ClaimQuota;
