import {
  Button,
  Flex,
  Image,
  Modal,
  Typography,
  Radio,
  Space,
  Tooltip,
  Divider,
} from "antd";
import bulkIMG from "../../assets/images/bulk_download.svg";
import { ICExclamationCircleOutlined } from "../list/ListIcon";
import ModalSuccess from "./ModalSuccess";

const { Text } = Typography;

export const ModalDownloadBulk = ({
  openModalDownload,
  setOpenModalDownload,
  radioDownload,
  setRadioDownload,
  detailData,
}) => {
  const onChange = (e) => {
    setRadioDownload(e.target.value);
  };

  const validEmail = detailData?.details
    ? detailData?.details?.actualRecords -
      detailData?.details?.totalInvalidEmail
    : detailData?.actualRecord - detailData?.invalidEmail;

  const fullEmail = detailData?.details
    ? detailData?.details.actualRecords
    : detailData?.actualRecord;

  const handleDownload = () => {
    if (radioDownload === "valid") {
      window.location.href = `${detailData?.validFileLink}`;
      return ModalSuccess({
        title: "List email berhasil didownload",
        customMessage:
          "Valid email report berhasil didownload. Silakan dapat mengecek kembali file tersebut",
        type: "primary",
        width: 500,
        onOk: () => {
          setOpenModalDownload(false);
          setRadioDownload("valid");
        },
      });
    } else if (radioDownload === "full") {
      window.location.href = `${detailData?.resultFileLink}`;
      return ModalSuccess({
        title: "List email berhasil didownload",
        customMessage:
          "Full email report berhasil didownload. Silakan dapat mengecek kembali file tersebut",
        type: "primary",
        width: 500,
        onOk: () => {
          setOpenModalDownload(false);
          setRadioDownload("valid");
        },
      });
    }
  };

  return (
    <Modal
      centered
      open={openModalDownload}
      onCancel={() => setOpenModalDownload(false)}
      width={476}
      footer={[
        <Flex justify="center">
          <Button key="Close" type="primary" onClick={handleDownload}>
            Download Result
          </Button>
        </Flex>,
      ]}
    >
      <Flex vertical gap={16}>
        <Flex vertical align="center">
          <Image src={bulkIMG} preview={false} />
          <Text className="text-base font-medium pt-2 pb-1">
            Download File Anda
          </Text>
          <Text className="text-secondary">
            File anda telah tersedia dan siap untuk didownload.
          </Text>
        </Flex>
        <Flex vertical className="px-20">
          <Text className="font-medium">Download option</Text>
          <Divider className="mt-1 mb-2 color-secondary-3" />
          <Radio.Group
            value={
              (detailData || detailData?.details) && validEmail === 0
                ? "full"
                : radioDownload
            }
            onChange={onChange}
            className="pb-5"
          >
            <Space direction="vertical">
              <Radio
                value="valid"
                disabled={
                  (detailData || detailData?.details) && validEmail === 0
                }
              >
                Valid email report{" "}
                <span className="font-medium">({validEmail} emails)</span>
                <Tooltip
                  title="Download hanya list email yang valid"
                  placement="right"
                >
                  <ICExclamationCircleOutlined className="text-secondary bulk-download" />
                </Tooltip>
              </Radio>
              <Radio value="full">
                Full email report{" "}
                <span className="font-medium">({fullEmail} emails)</span>
                <Tooltip
                  title="Download seluruh list email yang ada"
                  placement="right"
                >
                  <ICExclamationCircleOutlined className="text-secondary bulk-download" />
                </Tooltip>
              </Radio>
            </Space>
          </Radio.Group>
        </Flex>
      </Flex>
    </Modal>
  );
};
