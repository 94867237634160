import HeaderSection from "../../../../components/header/HeaderSection";
import { Image, Result } from "antd";
import footerImage from "../../../../assets/images/Accent.svg";
import AntButton from "../../../../components/AntButton";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { resendVerification } from "../../../../api";
import { secondsToTime } from "../../../../utils/helpers";

const VerifikasiProcess = () => {
  const { state } = useLocation();
  const [sendEmail, setSendEmail] = useState(false);
  const [counter, setCounter] = useState(5);
  const statusProcess = state.status;
  if (!state) window.location.href = "/login";

  if (statusProcess === "Success" && counter <= 0) {
    window.location.href = "/register/verifikasi";
  }

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  const resendEmail = async () => {
    setSendEmail(true);
    setCounter(120);
    await resendVerification();
  };

  const resendRender = (
    <div className="pt-7">
      <p className="text-success m-0 text-sm">
        Email verifikasi sudah berhasil kami kirimkan ulang, silakan cek email
        anda.
      </p>
      <p className="text-success m-0 text-sm">
        Email dapat dikirim ulang kembali dalam waktu {secondsToTime(counter)}.
      </p>
    </div>
  );

  const ProcessResponse = ({ status, verify }) => (
    <>
      <Result
        className="finish-change"
        status={
          status === "Success"
            ? "success"
            : status === "Failed"
            ? "error"
            : "success"
        }
        title={
          status === "Success" && !verify ? (
            <span className="font-medium text-3xl">
              Verifikasi Email Berhasil
            </span>
          ) : status === "Failed" ? (
            <span className="font-medium text-3xl">Link Tidak Valid</span>
          ) : status === "warning" ? (
            <span className="font-medium text-3xl">Akun Anda Ditangguhkan</span>
          ) : (
            <span className="font-medium text-3xl">
              Email Sudah Terverifikasi
            </span>
          )
        }
        subTitle={
          status === "Success" && !verify ? (
            <span>
              Akun anda sudah selesai didaftarkan pada sistem, halaman akan
              diredirect ke halaman berikutnya dalam {counter} detik..
              <br />
              <br /> Jika tidak kunjung tampil, silakan klik{" "}
              <a href="">disini</a>
            </span>
          ) : status === "Failed" ? (
            <>
              <span>
                Link verifikasi email anda sudah expired. Untuk melakukan
                verifikasi email silakan dapat melakukan permintaan kirim email
                verifikasi kembali.
              </span>
              {sendEmail ? resendRender : undefined}
              <AntButton
                type="text"
                children="Kirim ulang email verifikasi"
                className={`text-success ${sendEmail ? "mt-2" : "mt-4"}`}
                onClick={resendEmail}
                disabled={sendEmail}
              />
            </>
          ) : status === "warning" ? (
            <>
              <span>
                Akun anda ditangguhkan karena tidak melakukan verifikasi email
                dalam waktu 7 hari. Silakan dapat melakukan registrasi ulang
                untuk dapat menggunakan layanan kami.
              </span>
              <AntButton
                type="text"
                children="Akses Halaman Registrasi"
                className="text-success mt-4"
              />
            </>
          ) : (
            <span>
              Akun anda sudah dilakukan verifikasi sebelumnya, anda tidak perlu
              melakukan verifikasi ulang. Halaman ini akan diredirect ke halaman
              dashboard dalam 5 detik..
              <br />
              <br /> Jika tidak kunjung tampil, silakan klik{" "}
              <a href="">disini</a>
            </span>
          )
        }
      />
    </>
  );

  return (
    <div className="verifikasi-user">
      <HeaderSection title="header-flow" />
      <div className="mx-auto pt-20">
        <div className="flex flex-col justify-start flow-user mx-auto">
          <ProcessResponse status={statusProcess} verify={false} />
        </div>
      </div>
      <div className="image-container">
        <Image src={footerImage} preview={false} className="fixed-bottom" />
      </div>
    </div>
  );
};

export default VerifikasiProcess;
