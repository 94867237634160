import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoutes = ({
  user,
  redirectPath = "/login",
  children,
  token,
}) => {
  const {
    status = user?.data?.status,
    isSetup = user?.data?.isSetup,
    isClaimQuota = user?.data?.isClaimQuota,
    isCompanySetup = user?.data?.isCompanySetup,
  } = user;

  let element = children ? children : <Outlet />;
  if (!token) {
    element = <Navigate to={redirectPath} replace />;
  } else if (user && status === "inactive") {
    element = <Navigate to="/register/verifikasi" replace />;
  } else if (user && status === "active") {
    if (
      (isSetup && isClaimQuota && isCompanySetup) ||
      (isSetup && !isClaimQuota && isCompanySetup)
    )
      element = children;
    else if (!isSetup && !isClaimQuota && !isCompanySetup)
      element = <Navigate to="/register/verifikasi" replace />;
    else if (isSetup && isClaimQuota && !isCompanySetup)
      element = <Navigate to="/register/company-data" replace />;
  }

  return element;
};

export default ProtectedRoutes;
