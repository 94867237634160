import {
  ConfigProvider,
  Flex,
  Table,
  Tag,
  Button,
  Badge,
  Tooltip,
  Typography,
} from "antd";
import { TableTitle } from "../../table-title/TableTitle";
import { emptyHistoryValidation } from "../../empty/EmptyComponent";
import { splitDate } from "../../../utils/helpers";
import Spinner from "../../Spinner/Spinner";
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import {
  ICExclamationCircleOutlined,
  ICEyeOutlined,
} from "../../list/ListIcon";
import { ModalDetailList } from "../../modal/ModalDetail";

const { Text } = Typography;

export const TableSingleValidate = ({
  handleSearch,
  changeSearchQuery,
  changePage,
  changePageLimit,
  currentPage,
  currentLimit,
  searchValue,
  showSkeleton,
  dataValidate,
  setFilterSingle,
  filterSingle,
  getListValidate,
  props,
}) => {
  const {
    showModalDetailList,
    openModalDetailList,
    closeModalDetailList,
    listRecord,
  } = props;

  const columnSingle = [
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "Date",
      width: 112,
      style: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      },
      render: (data) => {
        const { date, time } = splitDate(data);
        return (
          <div className="flex flex-col">
            <p className="mb-0 mt-0">{date}</p>
            <p className="mb-0 mt-0 text-secondary text-xs" type="secondary">
              {time}
            </p>
          </div>
        );
      },
    },
    {
      title: "Email Address",
      dataIndex: "email",
      key: "Email Address",
      minWidth: 240,
      style: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        flex: "1 0 0",
      },
      render: (email) => (
        <Text copyable={{ tooltips: "Copy Email" }} className="email-single">
          {email}
        </Text>
      ),
    },

    {
      title: "Validation Status",
      key: "Validation Status",
      minWidth: 90,
      style: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        flex: "1 0 0",
        maxWidth: 112,
      },
      render: ({ valid, type }) => {
        return (
          <Badge
            status={
              valid === "risky"
                ? "warning"
                : valid === "valid"
                ? "success"
                : "error"
            }
            text={
              valid === "risky"
                ? "Risky Email"
                : valid === "valid"
                ? type === "advance"
                  ? "Valid Email"
                  : "Possibility Valid"
                : "Invalid Email"
            }
            className="font-medium"
          />
        );
      },
    },
    {
      title: "Email Type",
      dataIndex: "emailType",
      key: "Email Type",
      maxWidth: 140,
      style: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        flex: "1 0 0",
        alignSelf: "scretch",
      },
      render: (type) => {
        return (
          <Tag
            color={type !== "Profesional" && "#FFF7E6"}
            style={type !== "Profesional" && { borderColor: "#FFD591" }}
            className="mr-0"
          >
            {type === "Profesional" ? (
              <span className="font-medium">Professional</span>
            ) : (
              <span className="font-medium" style={{ color: "#FA8C16" }}>
                Free Email
              </span>
            )}
          </Tag>
        );
      },
    },
    {
      title: "Server Status",
      dataIndex: "serverStatus",
      key: "Server Status",
      minWidth: 90,
      style: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        flex: "1 0 0",
        alignSelf: "scretch",
        maxWidth: 140,
      },
      render: (status) => {
        return (
          <Tag
            className="mr-0"
            style={
              status === true
                ? { borderColor: "#91D5FF", background: "#E6F7FF" }
                : { borderColor: "#FFA39E", background: "#FFF1F0" }
            }
          >
            {status === true ? (
              <div className="font-medium" style={{ color: "#1890FF" }}>
                <CheckCircleFilled />
                <span className="ml-2">Valid</span>
              </div>
            ) : (
              <div className="font-medium" style={{ color: "#F5222D" }}>
                <CloseCircleFilled />
                <span className="ml-2">Invalid</span>
              </div>
            )}
          </Tag>
        );
      },
    },
    {
      key: "actions",
      fixed: "right",
      style: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        flex: "1 0 0",
        alignSelf: "scretch",
      },
      render: (_, record) => (
        <Flex justify="center" align="center">
          <Button
            type="link"
            className="text-success"
            icon={<ICEyeOutlined />}
            onClick={() => openModalDetailList(record)}
          >
            Lihat Detail
          </Button>
        </Flex>
      ),
    },
  ];

  const titleComp = (
    <>
      <Text className="pr-1 text-base">Single Validation History</Text>
      <Tooltip
        title="History per email hanya tersedia untuk single validation"
        overlayInnerStyle={{ width: "max-content" }}
      >
        <ICExclamationCircleOutlined className="text-secondary text-sm" />
      </Tooltip>
    </>
  );

  return (
    <ConfigProvider
      renderEmpty={() => {
        if (dataValidate?.data?.rows === null) {
          return emptyHistoryValidation({
            title: "Data tidak ditemukan",
            message: "Alamat email tersebut tidak ditemukan pada sistem",
          });
        } else {
          return emptyHistoryValidation({
            title: "Data belum tersedia",
            message: (
              <span>
                Anda belum melakukan validasi email, silakan refresh untuk
                <br /> memperbarui data
              </span>
            ),
          });
        }
      }}
    >
      <div className="flex flex-col">
        <TableTitle
          dataJSON={dataValidate}
          showSkeleton={showSkeleton}
          searchValue={searchValue}
          handleSearch={handleSearch}
          changeSearchQuery={changeSearchQuery}
          changePageLimit={changePageLimit}
          getListValidate={getListValidate}
          title={titleComp}
          description="History email yang telah divalidasi sebelumnya (max. 250 emails)"
        />
        {!dataValidate ? (
          <Flex justify="center" align="center" style={{ height: "40vh" }}>
            <Spinner />
          </Flex>
        ) : (
          <>
            <Table
              columns={columnSingle}
              dataSource={dataValidate?.data?.rows}
              style={{
                overflowX: "auto",
                maxWidth: "100%",
              }}
              pageLimit={{
                currentLimit: setFilterSingle.limit,
                onSelectLimit: (limit) => {
                  setFilterSingle({
                    ...filterSingle,
                    page: 1,
                    limit,
                  });
                },
                disable: dataValidate?.data?.page?.totalCount < 10,
              }}
              pagination={{
                pageSize: currentLimit,
                current: currentPage,
                total: dataValidate?.data?.page?.totalCount,
                showSizeChanger: false,
                onChange: changePage,
              }}
              loading={showSkeleton}
            />
            <ModalDetailList
              showModalDetail={showModalDetailList}
              closeModalDetail={closeModalDetailList}
              listRecord={listRecord}
            />
          </>
        )}
      </div>
    </ConfigProvider>
  );
};
