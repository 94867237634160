import { useCallback, useEffect, useState } from "react";
import DashboardHeader from "../../../components/dashboard-header/DashboardHeader";
import { TableApiKey } from "../../../components/table/api/TableApiKey";
import useDocumentTitle from "../../../utils/useDocumentTitle";
import { Row, Col, Card } from "antd";
import { apiKeyList } from "../../../api";
import { DetailsApi } from "../../../components/card/api-key/DetailsApi";
import SampleResponse from "../../../components/card/api-key/SampleResponse";

const ApiKey = () => {
  useDocumentTitle("API Key");
  const [listApiKey, setListApiKey] = useState(null);
  const [loading, setLoading] = useState(false);

  const getListApiKey = useCallback(async () => {
    setLoading(true);
    const { data, statusCode } = await apiKeyList();
    setListApiKey({ data, statusCode });
    setLoading(false);
  }, []);

  useEffect(() => {
    getListApiKey();
  }, [getListApiKey]);

  const propsList = {
    statusCode: listApiKey?.statusCode,
    dataRow: listApiKey?.data?.rows,
    listApiKey,
    getListApiKey,
    loading,
  };
  return (
    <>
      <DashboardHeader title="API Key" />
      <Row className="px-3 py-3 mx-0" gutter={[24, 24]}>
        <Col xl={12} lg={12} md={24} sm={24} xs={24}>
          <Card title="API Details" className="card-api">
            <DetailsApi />
          </Card>
        </Col>
        <Col xl={12} lg={12} md={24} sm={24} xs={24}>
          <SampleResponse />
        </Col>
        <Col span={24}>
          <Card loading={!listApiKey}>
            <TableApiKey propsList={propsList} />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ApiKey;
