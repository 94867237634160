import Spinner from "../components/Spinner/Spinner";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, Outlet } from "react-router-dom";
import { getCookieToken } from "./helpers";

const CheckLogin = () => {
  const { pathname: path } = useLocation();
  const navigate = useNavigate();
  const user = useSelector(({ userData }) => userData.value);
  const token = getCookieToken();

  const {
    status = user?.data?.status,
    isSetup = user?.data?.isSetup,
    isClaimQuota = user?.data?.isClaimQuota,
    isCompanySetup = user?.data?.isCompanySetup,
  } = user;

  useEffect(() => {
    if (!token && !user) window.location.href = "/login";
    else {
      if (user && isSetup) {
        if (isClaimQuota && !isCompanySetup) {
          navigate("/register/company-data");
        } else {
          navigate("/main");
        }
      }
    }
  }, [
    navigate,
    path,
    token,
    user,
    status,
    isClaimQuota,
    isCompanySetup,
    isSetup,
  ]);

  return user ? <Outlet /> : <Spinner full />;
};

export default CheckLogin;
