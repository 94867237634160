import { claimFreeQuota } from "../../api";
import { ICQuestionCircleOutlined } from "../list/ListIcon";
import ModalConfirm from "./ModalConfirm";
import ModalSuccess from "./ModalSuccess";

export const ModalClaim = (companyUUID) => {
  return ModalConfirm({
    title: "Klaim gratis kuota Email Validator?",
    customMessage: (
      <span>
        Apakah anda yakin ingin melakukan klaim gratis kuota untuk melakukan
        validasi email? Anda akan mendapatkan <strong>100 kredit</strong> kuota
        validasi email secara gratis.
      </span>
    ),
    okText: "Klaim Sekarang",
    type: "primary",
    cancelText: "Batalkan",
    icon: <ICQuestionCircleOutlined />,
    width: 500,
    className: "quota-claim",
    onOk: async () => {
      const { statusCode } = await claimFreeQuota({ CompanyUuid: companyUUID });
      if (statusCode === 200) {
        ModalSuccess({
          title: "Kuota gratis berhasil didapatkan",
          customMessage: (
            <span>
              Anda berhasil mengklaim 100 kredit kuota validasi email.
              <br /> Silakan lengkapi informasi perusahaan anda terlebih dahulu
              sebelum melakukan validasi email.
            </span>
          ),
          okText: "Lengkapi data",
          type: "primary",
          className: "quota-claim",
          onOk: () => (window.location.href = "/register/company-data"),
        });
      } else {
        ModalConfirm({
          title: "Gagal mengklaim kuota ",
          customMessage:
            "Kuota gagal diklaim oleh sistem. Terdapat kendala pada sistem, harap coba lagi atau hubungi tim Support kami.",
          okText: "Oke",
          cancelText: "Hubungi Support",
          width: 500,
          type: "primary",
          className: "error-claim",
        });
      }
    },
  });
};
