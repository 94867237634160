import { Modal } from "antd";

const { success } = Modal;

const defaultFn = () => undefined;

const ModalSuccess = ({
  title,
  message,
  customMessage,
  type,
  width,
  onOk = defaultFn,
  okText = "Oke",
  className,
  icon,
}) => {
  const messageModal = customMessage || <p className="mb-0 mt-2">{message}</p>;
  return success({
    title: title,
    width: width || 500,
    content: messageModal,
    className: className,
    centered: true,
    icon: icon,
    okButtonProps: { type: type, className: "btn-modal" },
    okText,
    onOk: () => {
      onOk();
      Modal.destroyAll();
    },
  });
};

export default ModalSuccess;
