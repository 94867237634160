import { Affix, Button, Card, Flex, Menu, Typography, Progress } from "antd";
import { Link } from "react-router-dom";
import {
  ICUserOutlined,
  ICPoweroffOutlined,
  ICInfoCircleOutlined,
} from "./list/ListIcon";
import { handleLogout } from "../utils/handleFunction";
import { formatNumberWithDot } from "../utils/helpers";
import { BtnClaimQuota, BtnAddQuota } from "./AntButton";
import { ModalInfoValidate } from "./modal/ModalInfoValidate";
import { useState } from "react";

const { Text } = Typography;

export const UserDropdown = ({ userName, userEmail }) => {
  return (
    <>
      <Affix offsetTop={64} style={{ width: 250 }}>
        <div className="bg-white navbarUser">
          <div className="flex flex-col px-3 py-1 h-auto bg-white">
            <p className="mb-0 mt-1">{userName}</p>
            <p className="text-secondary mb-0 mt-0">{userEmail}</p>
          </div>
          <Menu
            className="dropdownMenu"
            items={[
              {
                key: "profile",
                label: (
                  <Link to={"/profile"}>
                    <p className="mb-0 mt-0">Profil Saya</p>
                  </Link>
                ),
                icon: <ICUserOutlined />,
              },
            ]}
            style={{ padding: 0 }}
          />
          <Button
            type="link"
            icon={<ICPoweroffOutlined className="text-xs" rotate={90} />}
            className="btn-logout text-danger"
            onClick={handleLogout}
          >
            Keluar
          </Button>
        </div>
      </Affix>
    </>
  );
};

export const QuotaDropdown = ({
  totalBasicQuota,
  quotaBasicUsed,
  percentageBasicUsed,
  totalAdvancedQuota,
  quotaAdvancedUsed,
  percentageAdvancedUsed,
  isClaimQuota,
  creditRemain,
  companyUUID,
}) => {
  const [openModalInfo, setOpenModalInfo] = useState(false);

  const colorStrokeBasic =
    percentageBasicUsed <= 80
      ? "#0D9F3F"
      : percentageBasicUsed <= 90
      ? "#FAAD14"
      : "#FF4D4F";

  const colorStrokeAdvanced =
    percentageAdvancedUsed <= 80
      ? "#0D9F3F"
      : percentageAdvancedUsed <= 90
      ? "#FAAD14"
      : "#FF4D4F";

  return (
    <Card
      title={
        <Flex justify="space-between">
          <Text className="font-normal">Kredit Tersedia</Text>
          <Text className="text-sm font-medium text-secondary">
            {creditRemain ? formatNumberWithDot(creditRemain) : 0}
          </Text>
        </Flex>
      }
      className="w-250 quota-dropdown"
    >
      {creditRemain < 1 ? (
        <Flex vertical align="center" gap={8}>
          <ICInfoCircleOutlined
            className="color-secondary-25"
            style={{ fontSize: 32 }}
          />
          <Text className="color-secondary-25 text-base">
            Tidak ada kuota tersedia
          </Text>
          {!isClaimQuota ? (
            <BtnClaimQuota
              className={"w-full mb-4"}
              companyUUID={companyUUID}
            />
          ) : (
            <BtnAddQuota className={"w-full mb-4"} />
          )}
        </Flex>
      ) : (
        <>
          <Flex justify="space-between">
            <Text className="font-normal">Basic</Text>
            <Text className="text-xs font-medium text-secondary">
              {formatNumberWithDot(quotaBasicUsed)}/
              {formatNumberWithDot(totalBasicQuota)} ({percentageBasicUsed}%)
            </Text>
          </Flex>
          <Progress
            percent={percentageBasicUsed}
            strokeColor={colorStrokeBasic}
            showInfo={false}
          />
          <Flex justify="space-between">
            <Text className="font-normal">Advanced</Text>
            <Text className="text-xs font-medium text-secondary">
              {formatNumberWithDot(quotaAdvancedUsed)}/
              {formatNumberWithDot(totalAdvancedQuota)} (
              {percentageAdvancedUsed}%)
            </Text>
          </Flex>
          <Progress
            percent={percentageAdvancedUsed}
            strokeColor={colorStrokeAdvanced}
            showInfo={false}
          />
          <Flex justify="flex-end">
            <Text
              className="text-info text-3xs link-text"
              onClick={() => setOpenModalInfo(true)}
            >
              Pelajari Basic & Advanced
            </Text>
          </Flex>
          <ModalInfoValidate
            openModalInfo={openModalInfo}
            setOpenModalInfo={setOpenModalInfo}
          />
        </>
      )}
    </Card>
  );
};
