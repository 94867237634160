import { Typography, Card } from "antd";
import lineEmpty from "../../assets/images/empty-validate.svg";
import { Pie, Line } from "@ant-design/charts";
import { ValidateEmail } from "../AntButton";

const { Title, Text } = Typography;

export const CircleChart = ({ dataPie }) => {
  const data = [
    {
      type: "Valid Email",
      value: dataPie?.totalValid,
    },
    {
      type: "Invalid Email",
      value: dataPie?.totalInvalid,
    },
  ];
  const config = {
    appendPadding: 10,
    data,
    angleField: "value",
    colorField: "type",
    color: ({ type }) => {
      return type === "Valid Email" ? "#0D9F3F" : "#FF4D4F";
    },
    radius: 0.9,
    label: {
      type: "inner",
      offset: "-30%",
      content: ({ value }) => value,
      style: {
        fontSize: 14,
        textAlign: "center",
      },
    },
    legend: {
      position: "bottom-left",
      layout: "vertical",
      spacing: 12,
    },
    interactions: [
      {
        type: "element-active",
      },
    ],
  };

  if (!dataPie) return false;

  return (
    <Card>
      <Pie {...config} />
    </Card>
  );
};

export const LineChart = ({ data = [] }) => {
  const config = {
    data,
    height: 455,
    autoFit: true,
    xField: "date",
    yField: "value",
    seriesField: "category",
    color: ({ category }) => {
      return category === "valid" ? "#0D9F3F" : "#FF4D4F";
    },
  };

  if (data && data.length === 0)
    return (
      <div className="flex flex-col items-center justify-center h-screen-480 max-w-400 text-center m-auto empty-line-chart-text">
        <img src={lineEmpty} alt="Line Chart" />
        <Title level={5}>Data belum tersedia</Title>
        <Text type="secondary" className="font-normal">
          Grafik tidak tersedia karena tidak ada data terbaru, silakan lakukan
          validasi email agar terdapat data terbaru.
        </Text>
        <ValidateEmail />
      </div>
    );

  return <Line {...config} className="max-w-full mt-4" />;
};
