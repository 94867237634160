import { Table, Typography } from "antd";
import { ICArrowRightOutlined } from "../../list/ListIcon";

const { Text } = Typography;

export const DetailsApi = () => {
  const columns = [
    {
      title: "params",
      dataIndex: "params",
      key: "params",
      width: 150,
      className: "params-value-col",
      render: (params) => <Text className="font-medium">{params}</Text>,
    },
    {
      title: "values",
      dataIndex: "values",
      key: "values",
      className: "params-value-col",
      render: (values, index) =>
        index.params === "API Endpoint" ? (
          <Text copyable className="text-copy">
            {values}
          </Text>
        ) : index.params === "Collection" ? (
          <>
            <a
              href="https://www.postman.com/orange-meteor-722312/workspace/aktiva-email-validator-api/collection/27998595-b8c83b9d-f544-4cff-ad78-97b72c16c919?action=share&creator=27998595"
              className="text-primary underline"
              target="_blank"
              rel="noreferrer"
            >
              Postman Collection
            </a>
            <span className="text-success ml-1">
              <ICArrowRightOutlined />
            </span>
          </>
        ) : (
          <Text>{values}</Text>
        ),
    },
  ];

  const dataSource = [
    {
      key: "1",
      params: "API Endpoint",
      values: "https://api-validator.aktiva.co.id/app/v1/",
    },
    {
      key: "2",
      params: "Collection",
    },
    {
      key: "3",
      params: "Method",
      values: "GET, POST",
    },
  ];

  return (
    <div style={{ overflowX: "auto" }}>
      <Typography.Text className="text-secondary">
        Untuk menggunakan API, pastikan kuota anda tersedia
      </Typography.Text>
      <Table
        dataSource={dataSource}
        columns={columns}
        showHeader={false}
        pagination={false}
        rowKey={(record) => record.values}
      />
    </div>
  );
};
