import {
  Input,
  Button,
  Alert,
  Form,
  Divider,
  Flex,
  Tooltip,
  Typography,
} from "antd";
import emptyResult from "../../../assets/images/empty-result.svg";
import Spinner from "../../Spinner/Spinner";
import { regexEmail, requiredRules } from "../../../utils/helpers";
import { ICExclamationCircleOutlined } from "../../list/ListIcon";
import { RadioValidate } from "../../radio/RadioButton";
import { ModalInfoValidate } from "../../modal/ModalInfoValidate";
import { ModalDetail } from "../../modal/ModalDetail";
import { Link } from "react-router-dom";
import {
  AlertResult,
  DetailResultBasicComponent,
  DetailResultAdvancedComp,
} from "../../Components";
import { AlertComp } from "../../alert/AlertComponent";

const { Text } = Typography;

export const ValidationEmailCard = ({
  handleValidation,
  processValidation,
  form,
  result,
  props,
}) => {
  const {
    lowQuota,
    notEnoughQuota,
    runningOutQuota,
    handleChangeEmail,
    checkEmail,
    lowState,
    notEnoughState,
    radioValue,
    setOpenModalInfo,
    openModalInfo,
  } = props;

  const firstStep =
    lowQuota ||
    notEnoughQuota ||
    processValidation ||
    (!processValidation && result);

  return (
    <div className={`pt-2 ${firstStep && "pb-4"}`}>
      <span className="pr-1">Input alamat email yang ingin divalidasi</span>
      <Tooltip
        title="Proses validasi email akan mengurangi kuota kredit anda"
        overlayInnerStyle={{ width: 370 }}
      >
        <ICExclamationCircleOutlined className="text-secondary" />
      </Tooltip>
      <Form form={form} className="flex gap-x-1 mt-2 mb-3">
        <Form.Item
          name="email"
          rules={[
            requiredRules,
            {
              pattern: regexEmail,
              message: "Harap masukkan alamat email yang vaild",
            },
          ]}
          className="w-full mb-0"
        >
          <Input
            placeholder="target@domain.com"
            disabled={processValidation}
            onChange={(e) => handleChangeEmail(e.target.value)}
          />
        </Form.Item>
        <Form.Item className="mb-0">
          <Button
            type="primary"
            onClick={() => handleValidation()}
            disabled={processValidation || !checkEmail || !radioValue}
            htmlType="submit"
          >
            Validate
          </Button>
        </Form.Item>
      </Form>
      <Flex justify="space-between" className="pb-4">
        <RadioValidate props={props} processValidation={processValidation} />
        <Text
          className="text-info text-right link-text text-xs font-medium"
          onClick={() => setOpenModalInfo(true)}
        >
          Apa perbedaan keduanya?
        </Text>
      </Flex>
      <ModalInfoValidate
        openModalInfo={openModalInfo}
        setOpenModalInfo={setOpenModalInfo}
      />
      {processValidation ? (
        <Alert
          message="Validasi email sedang dilakukan, mohon tunggu"
          type="info"
          showIcon
          action={processValidation && <Spinner />}
          className="mt-1"
        />
      ) : !processValidation && result && !(notEnoughState || lowState) ? (
        <Alert
          message={
            result?.statusCode === 200
              ? "Validasi email berhasil dilakukan"
              : "Terdapat kendala pada sistem, harap dapat mencoba kembali."
          }
          type={result?.statusCode === 200 ? "success" : "error"}
          showIcon
          className="mt-1"
        />
      ) : notEnoughQuota || (notEnoughQuota && notEnoughState) ? (
        <Alert
          message={
            "Kuota validasi email anda habis, harap lakukan penambahan kuota"
          }
          type={"error"}
          showIcon
          className="mt-1"
        />
      ) : lowQuota || (lowQuota && lowState) ? (
        <Alert
          message={
            "Kuota validasi email anda akan habis, segera lakukan penambahan kuota"
          }
          type={"warning"}
          showIcon
          className="mt-1"
        />
      ) : runningOutQuota ? (
        <Alert
          message={
            "Kuota validasi email anda habis, harap lakukan penambahan kuota"
          }
          type={"error"}
          showIcon
          className="mt-1"
        />
      ) : undefined}
    </div>
  );
};

export const ResultEmailCard = ({ result, showSpinner, props }) => {
  const {
    statusCode = result?.statusCode,
    openRelay = result?.data?.detail?.openRelay,
    email = result?.data?.detail?.email,
    emailType = result?.data?.detail?.emailType,
    domainStatus = result?.data?.detail?.domainStatus,
    serverStatus = result?.data?.detail?.serverStatus,
    emailFormat = result?.data?.detail?.emailFormat,
    disposableEmail = result?.data?.detail?.disposableEmail,
    roleEmail = result?.data?.detail?.roleEmail,
    validationType = result?.data?.detail?.validationType,
    valid = result?.data?.valid,
    type = result?.data?.type,
  } = result;

  const validPotential =
    valid === "valid" &&
    !disposableEmail &&
    domainStatus &&
    serverStatus &&
    emailFormat &&
    (!roleEmail || (type === "basic" && !roleEmail));

  const serverUnknown =
    !disposableEmail && domainStatus && !serverStatus && emailFormat;

  const domainInvalid =
    !disposableEmail && !domainStatus && !serverStatus && emailFormat;

  const disposable =
    disposableEmail && domainStatus && serverStatus && emailFormat;

  const maybeSave =
    (type === "advance" ? valid === "risky" : "") &&
    openRelay &&
    !disposableEmail &&
    domainStatus &&
    serverStatus &&
    emailFormat;

  const maybeInvalid =
    !disposableEmail &&
    domainStatus &&
    serverStatus &&
    emailFormat &&
    roleEmail;

  const bouncedEmail =
    (type === "advance" ? valid === "invalid" : "") &&
    !openRelay &&
    !disposableEmail &&
    domainStatus &&
    serverStatus &&
    emailFormat;

  const propsState = {
    validPotential,
    serverUnknown,
    domainInvalid,
    disposable,
    maybeSave,
    bouncedEmail,
    maybeInvalid,
  };

  const { showModalDetail, openModalDetail, closeModalDetail, alertSaveQuota } =
    props;

  return (
    <>
      {showSpinner ? (
        <Flex justify="center" align="center" style={{ height: "15vh" }}>
          <Spinner />
        </Flex>
      ) : result && statusCode === 200 ? (
        <div className="py-2">
          {alertSaveQuota && (
            <AlertComp
              message={`Selamat! Kamu telah menghemat kuota ${type} validation `}
              type={"info"}
              className="mb-3 alert-save"
            />
          )}
          <AlertResult email={email} type={type} propsState={propsState} />
          <Flex justify="center" className="mt-3">
            <Link className="text-success" onClick={openModalDetail}>
              Lihat detail
            </Link>
          </Flex>
          <ModalDetail
            showModalDetail={showModalDetail}
            closeModalDetail={closeModalDetail}
            result={result}
          />
          <Divider className="mt-3 mb-4" />
          {validationType === "basic" ? (
            <DetailResultBasicComponent
              emailType={emailType}
              domainStatus={domainStatus}
              serverStatus={serverStatus}
              emailFormat={emailFormat}
              disposableEmail={disposableEmail}
              roleEmail={roleEmail}
            />
          ) : (
            <DetailResultAdvancedComp
              emailType={emailType}
              domainStatus={domainStatus}
              serverStatus={serverStatus}
              emailFormat={emailFormat}
              openRelay={openRelay}
              disposableEmail={disposableEmail}
              valid={valid}
              roleEmail={roleEmail}
            />
          )}
        </div>
      ) : (
        <div className="flex flex-col items-center w-full pt-2 pb-2">
          <img src={emptyResult} alt="" className="mb-1" />
          <span className="text-sm font-medium mt-1">Data belum tersedia</span>
        </div>
      )}
    </>
  );
};
