import { Col, Row, Divider, Button, Tooltip, Flex, Skeleton } from "antd";
import { ICPlusOutlined, ICQuestionCircleOutlined } from "../../list/ListIcon";

export const SummaryValidate = ({
  openModalUploadBulk,
  summaryValidate,
  showSkeleton,
}) => {
  return (
    <>
      <Row gutter={24}>
        {!summaryValidate || showSkeleton ? (
          <Skeleton active title={false} />
        ) : (
          <>
            <Col xl={6} lg={12} md={12} sm={12} xs={24}>
              <div className="py-4 px-6 bg-white">
                <h5 className="mb-0 mt-0 text-3xl font-medium">
                  {summaryValidate?.totalUnprocessed}
                </h5>
                <span className="text-info font-medium">Unproccessed</span>
                <Divider className="my-2 mb-0 mt-2" />
              </div>
            </Col>
            <Col xl={6} lg={12} md={12} sm={12} xs={24}>
              <div className="py-4 px-6 bg-white">
                <h5 className="mb-0 mt-0 text-3xl font-medium">
                  {summaryValidate?.totalValidated}
                </h5>
                <span className="text-success font-medium">Completed</span>
                <Divider className="my-2 mb-0 mt-2" />
              </div>
            </Col>
            <Col xl={6} lg={12} md={12} sm={12} xs={24}>
              <div className="py-4 px-6 bg-white">
                <h5 className="mb-0 mt-0 text-3xl font-medium">
                  {summaryValidate?.totalBulkData}
                </h5>
                <span className="font-medium">Total Data</span>
                <Divider className="my-2 mb-0 mt-2" />
              </div>
            </Col>
            <Col xl={6} lg={12} md={12} sm={12} xs={24}>
              <div className="py-4 px-6 bg-white">
                <Flex>
                  <span className="text-base font-medium">
                    Mulai Validasi Email
                  </span>
                  <Tooltip
                    title="Validasi Email secara bulk melalui file list email anda"
                    placement="bottomRight"
                  >
                    <span className="text-secondary ml-1">
                      <ICQuestionCircleOutlined />
                    </span>
                  </Tooltip>
                </Flex>
                <Button
                  type="primary"
                  icon={<ICPlusOutlined />}
                  className="mt-2"
                  onClick={() => openModalUploadBulk()}
                >
                  Upload File
                </Button>
                <Divider className="my-2 mb-2 mt-2" />
              </div>
            </Col>
          </>
        )}
      </Row>
    </>
  );
};
