import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import {
  ICArrowRightOutlined,
  ICCheckOutlined,
  ICCreditCardOutlined,
} from "./list/ListIcon";
import { ModalClaim } from "./modal/ModalClaim";

const AntButton = ({
  type,
  children,
  htmlType,
  disabled,
  onClick,
  block,
  className,
  style,
  icon,
  href,
}) => {
  return (
    <>
      {type === "primary" && (
        <Button
          type={type}
          onClick={onClick}
          block={block}
          htmlType={htmlType}
          disabled={disabled}
          className={`btn ${className}`}
          style={style}
          icon={icon}
        >
          {children}
        </Button>
      )}
      {type === "text" && (
        <Button
          type={type}
          className={`w-full ${className}`}
          onClick={onClick}
          style={style}
          href={href}
          icon={icon}
          disabled={disabled}
        >
          {children}
        </Button>
      )}
    </>
  );
};

export default AntButton;

export const ChoosePacket = () => {
  return (
    <Button type="primary" icon={<ICArrowRightOutlined />} className="my-3">
      Beli kredit
    </Button>
  );
};

export const ValidateEmail = () => {
  const navigate = useNavigate();
  return (
    <Button
      type="primary"
      className="mt-3"
      onClick={() => navigate("/single-validation")}
    >
      Validasi Email
    </Button>
  );
};

export const BtnClaimQuota = ({ className, companyUUID }) => {
  return (
    <Button
      icon={<ICCheckOutlined />}
      type="primary"
      className={className}
      onClick={() => ModalClaim(companyUUID)}
    >
      Klaim gratis kuota
    </Button>
  );
};

export const BtnAddQuota = ({ className }) => {
  return (
    <Button
      icon={<ICCreditCardOutlined />}
      type="primary"
      className={className}
      target="_blank"
      href="https://www.aktiva.co.id/email-validator-order/"
    >
      Tambah kuota
    </Button>
  );
};
