import { CheckCircleFilled } from "@ant-design/icons";
import {
  Modal,
  Button,
  Typography,
  Flex,
  Row,
  Col,
  Card,
  List,
  Divider,
} from "antd";

const { Text } = Typography;

export const ModalInfoValidate = ({ openModalInfo, setOpenModalInfo }) => {
  const basicData = [
    "Email format check",
    "Domain validation",
    "Email server validation",
    "Email type checker",
    "Disposable email checker",
  ];

  const advanceData = [
    "Email format check",
    "Domain validation",
    "Email server validation",
    "Email type checker",
    "Disposable email checker",
    "Email validation status",
    "Open relay status",
  ];

  return (
    <Modal
      centered
      title="Informasi Validasi Email"
      open={openModalInfo}
      width={572}
      onCancel={() => setOpenModalInfo(false)}
      footer={[
        <Button
          key="Close"
          onClick={() => setOpenModalInfo(false)}
          type="default"
        >
          Close
        </Button>,
      ]}
    >
      <Flex vertical>
        <Row gutter={16}>
          <Col span={12}>
            <Card className="text-center card-radius">
              <Flex vertical>
                <Text className="font-medium text-base">Basic Validation</Text>
                <Text className="text-xs text-secondary">
                  Check basic information
                </Text>
                <Divider className="my-0 mt-3" />
                <List
                  bordered={false}
                  dataSource={basicData}
                  className="info-list"
                  renderItem={(item) => (
                    <List.Item>
                      <CheckCircleFilled className="text-success" />{" "}
                      <span className="ml-1">{item}</span>
                    </List.Item>
                  )}
                />
                <Divider className="my-0" />
              </Flex>
            </Card>
          </Col>
          <Col span={12}>
            <Card className="text-center advance-card card-radius">
              <Flex vertical>
                <Text className="font-medium text-base">
                  Advanced Validation
                </Text>
                <Text className="text-xs text-secondary">
                  Check full information
                </Text>
                <Divider className="my-0 mt-3" />
                <List
                  bordered={false}
                  dataSource={advanceData}
                  className="info-list"
                  renderItem={(item) => (
                    <List.Item>
                      <CheckCircleFilled className="text-success" />{" "}
                      <span className="ml-1">{item}</span>
                    </List.Item>
                  )}
                />
                <Divider className="my-0" />
              </Flex>
            </Card>
          </Col>
        </Row>
      </Flex>
    </Modal>
  );
};
