import { Button } from "antd";
import { useEffect, useState } from "react";
import {
  getCookie,
  removeCookie,
  removeStorage,
  secondsToTime,
  setCookie,
  setStorage,
} from "../../../../utils/helpers";
import { getUser, resendVerification } from "../../../../api";
import ModalSuccess from "../../../../components/modal/ModalSuccess";
import useDocumentTitle from "../../../../utils/useDocumentTitle";

const VerifikasiEmail = ({ next, title }) => {
  useDocumentTitle(title);
  const counterCookies = getCookie("r_btn");
  const [counter, setCounter] = useState(counterCookies || 0);
  const [valid, setvalid] = useState(true);
  const [resend, setResend] = useState(false);
  const [btnLoading, setBtnLoading] = useState("");

  useEffect(() => {
    const interval = setInterval(() => {
      if (counter > 0) {
        setResend(true);
        setCounter(counter - 1);
        setCookie("r_btn", counter - 1);
      } else if (counter === 0) {
        removeCookie(["r_btn"]);
        removeStorage("isValid");
        setResend(false);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [counter]);

  const checkVerification = async () => {
    setBtnLoading("check");
    const responseUser = await getUser();
    setBtnLoading("");
    if (responseUser?.data?.status === "inactive") {
      setStorage("isValid", "not valid");
      setvalid(false);
    } else if (responseUser?.data?.status === "active") {
      removeCookie(["r_btn"]);
      setvalid(true);
      removeStorage("isValid");
      next();
    }
  };

  const resendVerify = async () => {
    const { statusCode } = await resendVerification();
    if (statusCode === 200) {
      setBtnLoading("resend");
      setBtnLoading("");
      setResend(true);
      setStorage("isValid", "resend");
      setCookie("r_btn", 120);
      setCounter(120);
    } else {
      ModalSuccess({
        title: "Email Anda Sudah Terverifikasi",
        message:
          "Anda sudah berhasil melakukan verifikasi alamat email dan tidak perlu melakukan verifikasi kembali",
        width: 460,
        okText: "Selanjutnya",
        onOk: () => next(),
      });
    }
  };

  const notValid = (
    <span className="text-danger m-0 text-sm">
      Verifikasi email tidak valid. Silakan lakukan verifikasi email dengan
      benar.
    </span>
  );

  const resendRender = (
    <div>
      <p className="text-success m-0 text-sm">
        Email verifikasi sudah berhasil kami kirimkan ulang, silakan cek email
        anda.
      </p>
      <p className="text-success m-0 text-sm">
        Email dapat dikirim ulang kembali dalam waktu {secondsToTime(counter)}.
      </p>
    </div>
  );

  return (
    <>
      <div className="flex flex-col justify-start setup-form mx-auto mb-12">
        <h2 className="text-3xl mb-1 my-0 font-medium">
          Verifikasi Email Anda
        </h2>
        <p className="text-secondary text-sm my-0">
          Kami sudah berhasil membuat akun anda pada sistem. Selanjutnya lakukan
          verifikasi alamat email anda untuk dapat melanjutkan proses registrasi
          layanan kami. Periksa email dari kami pada inbox anda, jika mengalami
          kendala silakan dapat hubungi kami pada{" "}
          <a href="mailto:support@aktiva.co.id">support@aktiva.co.id</a>
        </p>
      </div>
      <div className="flex flex-col mx-auto text-center gap-y-2 items-center mb-2">
        {!valid && notValid}
        {resend && resendRender}
      </div>
      <div className="flex flex-col mx-auto text-center items-center gap-y-2">
        <Button
          className="btn-setup"
          type="primary"
          loading={btnLoading === "check"}
          onClick={checkVerification}
        >
          Saya sudah verifikasi
        </Button>
        <Button
          type="link"
          className="btn-setup text-success"
          disabled={counter !== 0}
          loading={btnLoading === "resend"}
          onClick={resendVerify}
        >
          Kirim ulang email verifikasi
        </Button>
      </div>
    </>
  );
};

export default VerifikasiEmail;
