import { Badge, Select, Card, Typography } from "antd";
import { useState, useRef, useEffect } from "react";

const { Option } = Select;
const { Paragraph } = Typography;

const SampleResponse = () => {
  const SelectStatus = [
    { value: "success", label: "200 - Success" },
    { value: "bad_request", label: "400 - Bad Request" },
    { value: "unauthorized", label: "401 - Unauthorized" },
    { value: "not_found", label: "404 - Not Found" },
    { value: "internal_error", label: "500 - Internal Error" },
  ];

  const [selectedStatus, setSelectedStatus] = useState(SelectStatus[0].value);
  const [dropdownWidth, setDropdownWidth] = useState("auto");
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (dropdownRef.current) {
      const textWidth = dropdownRef.current.offsetWidth;
      setDropdownWidth(`${textWidth}px`);
    }
  }, [selectedStatus]);

  const handleStatusChange = (value) => {
    setSelectedStatus(value);
  };

  const TitleSample = () => {
    return (
      <div className="flex gap-x-2 justify-between items-center">
        <span>Sample Response</span>
        <Select
          style={{ width: dropdownWidth }}
          onChange={handleStatusChange}
          value={selectedStatus}
          ref={dropdownRef}
        >
          {SelectStatus.map((option) => (
            <Option key={option.value} value={option.value}>
              <Badge color={option.value === "success" ? "green" : "red"} />
              <span style={{ marginLeft: "4px" }}>{option.label}</span>
            </Option>
          ))}
        </Select>
      </div>
    );
  };

  const SampleContent = () => {
    let sampleApiResponse = {};

    switch (selectedStatus) {
      case "success":
        sampleApiResponse = {
          data: {
            msg: "Starting validation process",
            status: "Success",
            statusCode: 200,
            data: {},
          },
        };
        break;
      case "bad_request":
        sampleApiResponse = {
          data: {
            msg: "Upload file exceeded limit",
            status: "Failed",
            statusCode: 400,
            data: {},
          },
        };
        break;
      case "unauthorized":
        sampleApiResponse = {
          data: {
            msg: "Not authorized",
            status: "Failed",
            statusCode: 401,
            data: {},
          },
        };
        break;
      case "not_found":
        sampleApiResponse = {
          data: {
            msg: "Bulk validation data not found",
            status: "Failed",
            statusCode: 404,
            data: {},
          },
        };
        break;
      case "internal_error":
        sampleApiResponse = {
          data: {
            msg: "Internal server error",
            status: "Failed",
            statusCode: 500,
          },
        };
        break;
      default:
        break;
    }

    return (
      <Paragraph className="response">
        <pre
          style={{
            padding: "5px 12px",
            margin: "0 0",
            background: "white",
            fontSize: "12px",
          }}
        >
          {JSON.stringify(sampleApiResponse.data, null, 2)}
        </pre>
      </Paragraph>
    );
  };

  return (
    <Card title={<TitleSample />} className="card-api">
      <SampleContent />
    </Card>
  );
};

export default SampleResponse;
