import { Menu, Form } from "antd";
import {
  ICEyeOutlined,
  ICDeleteOutlined,
  ICDownloadOutlined,
} from "./list/ListIcon";
import ModalSuccess from "./modal/ModalSuccess";
import ModalWarning from "./modal/ModalWarning";
import { useLocation, useNavigate } from "react-router-dom";
import { deleteListBulk } from "../api";
import { useState } from "react";
import { ModalDeleteBulk } from "./modal/ModalDelete";
import { ModalDownloadBulk } from "./modal/ModalDownloadBulk";

export const ActionTableBulk = ({
  record,
  refreshListBulk,
  refreshSummaryBulk,
}) => {
  const navigate = useNavigate();
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openModalDownload, setOpenModalDownload] = useState(false);
  const [radioDownload, setRadioDownload] = useState("valid");
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const Location = useLocation();

  const detailPage = Location.pathname === `/bulk-validation/${record.uuid}`;

  const handleInput = async () => {
    const confirmValue = form.getFieldValue(["confirmation"]);
    if (confirmValue === "confirm") {
      setLoading(true);
      const response = await deleteListBulk({ uuid: record.uuid });
      if (response.statusCode === 200) {
        ModalSuccess({
          title: "Data list email berhasil dihapus",
          message: "List email tersebut berhasil dihapus dari sistem",
          width: 500,
          type: "primary",
          onOk: () => {
            if (detailPage) {
              window.location.href = "/bulk-validation";
            } else {
              refreshListBulk();
              refreshSummaryBulk();
              form.resetFields();
              setOpenModalDelete(false);
              setLoading(false);
            }
          },
        });
      } else {
        ModalWarning({
          title: "Gagal menghapus data list email",
          message:
            "List email gagal dihapus dari sistem. Terdapat kendala pada sistem, harap coba lagi",
          width: 500,
          type: "primary",
          onOk: () => {
            form.resetFields();
            setOpenModalDelete(false);
            setLoading(false);
          },
        });
      }
    }
  };

  return (
    <>
      <Menu
        items={[
          !detailPage
            ? {
                label: "Show Details",
                key: 1,
                icon: <ICEyeOutlined />,
                onClick: () => {
                  navigate(`/bulk-validation/${record.uuid}`);
                },
              }
            : null,
          {
            label: "Download Result",
            key: 2,
            icon: <ICDownloadOutlined />,
            disabled: record.status !== "validated",
            onClick: () => setOpenModalDownload(true),
          },
          {
            label: "Delete Data",
            key: 3,
            icon: <ICDeleteOutlined />,
            danger: true,
            onClick: () => setOpenModalDelete(true),
          },
        ]}
      />
      <ModalDeleteBulk
        open={openModalDelete}
        setOpen={setOpenModalDelete}
        form={form}
        handleInput={handleInput}
        loading={loading}
      />
      <ModalDownloadBulk
        detailData={record}
        openModalDownload={openModalDownload}
        setOpenModalDownload={setOpenModalDownload}
        radioDownload={radioDownload}
        setRadioDownload={setRadioDownload}
      />
    </>
  );
};
