import { Modal, Form, Input, Checkbox } from "antd";
import {
  requiredRules,
  regexPassword,
  regexDomain,
  validateRepeatPassword,
  BanCharacters,
} from "../../utils/helpers";
import { useEffect } from "react";

export const ModalChangePassword = ({ propsModal }) => {
  const {
    openModalChange,
    handleCloseModalChange,
    form_change,
    isLoadingChange,
    handleFormChange,
    disableBtnChange,
    handleValidatePassword,
    handleValidateRepeatPassword,
  } = propsModal;

  return (
    <Modal
      open={openModalChange}
      onCancel={handleCloseModalChange}
      title={"Ubah Password"}
      centered
      okText="Simpan"
      width={572}
      cancelText="Batal"
      onOk={handleFormChange}
      okButtonProps={{ disabled: disableBtnChange }}
      confirmLoading={isLoadingChange}
    >
      <Form form={form_change} className="form-password" layout="vertical">
        <Form.Item
          label="Password saat ini"
          name="current_password"
          rules={[requiredRules]}
        >
          <Input.Password placeholder="input password" />
        </Form.Item>
        <Form.Item
          label="Password baru"
          name="password"
          rules={[
            requiredRules,
            {
              pattern: regexPassword,
              message:
                "Password harus mengandung 8 karakter dengan kombinasi huruf kapital, huruf kecil, angka dan simbol",
            },
          ]}
        >
          <Input.Password
            placeholder="input password"
            onChange={handleValidatePassword}
          />
        </Form.Item>
        <Form.Item
          label="Ulangi Password baru"
          name="new_password"
          rules={[
            {
              required: true,
              message: "Password tidak sesuai",
            },
            validateRepeatPassword,
          ]}
        >
          <Input.Password
            placeholder="input password"
            onChange={handleValidateRepeatPassword}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export const ModalInfoCompany = ({ propsModal }) => {
  const {
    openModalCompany,
    handleCloseModalCompany,
    dataCompany,
    form_company,
    isLoadingCompany,
    handleFormCompany,
  } = propsModal;

  useEffect(() => {
    if (!dataCompany) {
      form_company.resetFields();
    } else {
      form_company.setFieldsValue({
        companyName: dataCompany?.name,
        address: dataCompany?.address,
        phone: dataCompany?.phone,
        website: dataCompany?.website,
      });
    }
  }, [dataCompany, form_company]);

  return (
    <Modal
      title="Edit Informasi Perusahaan"
      centered
      okText="Simpan"
      cancelText="Batal"
      onOk={handleFormCompany}
      open={openModalCompany}
      onCancel={handleCloseModalCompany}
      confirmLoading={isLoadingCompany}
    >
      <Form
        layout="vertical"
        form={form_company}
        initialValues={{
          companyName: dataCompany?.name,
          address: dataCompany?.address,
          phone: dataCompany?.phone,
          website: dataCompany?.website,
        }}
      >
        <Form.Item
          label="Nama Perusahaan"
          name="companyName"
          className="mb-4"
          rules={[requiredRules]}
        >
          <Input placeholder="Company name" />
        </Form.Item>
        <Form.Item
          label="Alamat"
          name="address"
          className="mb-4"
          rules={[requiredRules]}
        >
          <Input.TextArea rows={2} placeholder="Your company address" />
        </Form.Item>
        <Form.Item
          label="Telepon"
          name="phone"
          className="mb-4"
          rules={[
            requiredRules,
            { min: 6, message: "Harap masukkan nomor yang valid" },
          ]}
        >
          <Input
            onKeyDown={(e) => {
              if (
                e.target.value.length >= 50 &&
                e.keyCode !== 8 &&
                e.keyCode !== 46
              ) {
                e.preventDefault();
              }
              if (BanCharacters.test(e.key) && e.key !== "Backspace") {
                e.preventDefault();
              }
            }}
            onWheel={(e) => e.preventDefault()}
            placeholder="02182XXXXX"
          />
        </Form.Item>
        <Form.Item
          label="Website"
          name="website"
          className="mb-4"
          rules={[
            requiredRules,
            {
              pattern: regexDomain,
              message: "Harap masukkan alamat website yang valid",
            },
          ]}
        >
          <Input addonBefore="https://" placeholder="yourdomain.com" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export const ModalInfoContact = ({ propsModal }) => {
  const {
    openModalContact,
    handleCloseModalContact,
    dataContact,
    form_contact,
    isLoadingContact,
    handleFormContact,
  } = propsModal;

  useEffect(() => {
    if (!dataContact) {
      form_contact.resetFields();
    } else {
      form_contact.setFieldsValue({
        name: dataContact.name,
        email: dataContact.email,
        phone: dataContact.phone,
      });
    }
  }, [dataContact, form_contact]);

  return (
    <Modal
      title="Edit Kontak"
      centered
      okText="Simpan"
      cancelText="Batal"
      onOk={handleFormContact}
      open={openModalContact}
      onCancel={handleCloseModalContact}
      confirmLoading={isLoadingContact}
    >
      <Form
        layout="vertical"
        form={form_contact}
        name="newContact"
        initialValues={{
          name: dataContact?.name,
          email: dataContact?.email,
          phone: dataContact?.phone,
        }}
      >
        <Form.Item
          name="name"
          label="Nama Lengkap"
          className="mb-4"
          rules={[requiredRules]}
        >
          <Input placeholder="Nama Lengkap" />
        </Form.Item>
        <Form.Item
          name="email"
          label="Alamat Email"
          className="mb-4"
          // rules={[requiredRules, { type: "email" }]}
        >
          <Input type="email" placeholder="nama@perusahaan.tld" disabled />
        </Form.Item>
        <Form.Item
          name="phone"
          label="Nomor Handphone"
          className="mb-4"
          rules={[
            requiredRules,
            { min: 8, message: "Harap masukkan nomor yang valid" },
          ]}
        >
          <Input
            placeholder="0812XXXXXXXX"
            onKeyDown={(e) => {
              if (
                e.target.value.length >= 50 &&
                e.keyCode !== 8 &&
                e.keyCode !== 46
              ) {
                e.preventDefault();
              }
              if (BanCharacters.test(e.key) && e.key !== "Backspace") {
                e.preventDefault();
              }
            }}
            onWheel={(e) => e.preventDefault()}
          />
        </Form.Item>
        <Form.Item
          name="contactRole"
          // rules={[
          //   {
          //     validator: (_, value) =>
          //       value
          //         ? Promise.resolve()
          //         : Promise.reject(
          //             new Error("Harap pilih role kontak tersebut")
          //           ),
          //   },
          //   { required: true, message: "Harap pilih role kontak tersebut" },
          // ]}
        >
          <Checkbox.Group disabled>
            <div className="flex flex-col gap-y-1">
              <Checkbox value="technical">
                Set sebagai kontak Technical
              </Checkbox>
              <Checkbox value="billing">Set sebagai kontak Billing</Checkbox>
            </div>
          </Checkbox.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
};
