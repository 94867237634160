import { Layout } from "antd";
import "./dashboardheader.less";

const { Header } = Layout;

const DashboardHeader = ({ title }) => {
  return (
    <Header className="dashboard-header">
      <h4 className="text-xl">{title}</h4>
    </Header>
  );
};

export default DashboardHeader;
