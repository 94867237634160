import { Radio } from "antd";
import { useLocation } from "react-router-dom";

export const ChartRadioButton = ({ value, setRadio }) => {
  const onRadioChange = ({ target: { value } }) => setRadio(value);
  return (
    <Radio.Group buttonStyle="solid" onChange={onRadioChange} value={value}>
      <Radio.Button value="week">Week</Radio.Button>
      <Radio.Button value="month">Month</Radio.Button>
    </Radio.Group>
  );
};

export const SummaryRadioButton = ({ value, setRadio }) => {
  const onRadioChange = ({ target: { value } }) => setRadio(value);
  return (
    <Radio.Group buttonStyle="solid" value={value} onChange={onRadioChange}>
      <Radio.Button value="all">All Time</Radio.Button>
      <Radio.Button value="day">Today</Radio.Button>
      <Radio.Button value="week">This Week</Radio.Button>
      <Radio.Button value="month">This Month</Radio.Button>
    </Radio.Group>
  );
};

export const RadioValidate = ({ props, processValidation, typeValidate }) => {
  const location = useLocation();
  const singlePage = location.pathname === "/single-validation";
  const { setRadioValue, radioValue, invalidated } = props;
  const onChange = (e) => {
    setRadioValue(e.target.value);
  };

  return (
    <Radio.Group
      value={typeValidate ? typeValidate : radioValue}
      onChange={onChange}
      disabled={singlePage ? processValidation : !invalidated}
    >
      <Radio value="basic">Basic Validation</Radio>
      <Radio value="advance">Advanced Validation</Radio>
    </Radio.Group>
  );
};
