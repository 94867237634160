export const headerCSVSingle = [
  { label: "Date", key: "createdAt" },
  { label: "Email", key: "email" },
  { label: "Email Valid", key: "result" },
  { label: "Email Format", key: "emailFormat" },
  { label: "Email Type", key: "emailType" },
  { label: "Domain Status", key: "domainStatus" },
  { label: "Accept All", key: "openRelay" },
  { label: "Server Status", key: "serverStatus" },
];

export const headerCSVBulk = [
  { label: "Date", key: "createdAt" },
  { label: "Name", key: "name" },
  { label: "Status", key: "status" },
  { label: "File Format", key: "fileFormat" },
  { label: "Actual Record", key: "actualRecord" },
  { label: "Valid Email", key: "validEmail" },
  { label: "Valid Percentage", key: "validEmailPercentage" },
  { label: "Invalid Email", key: "invalidEmail" },
  { label: "Invalid Percentage", key: "invalidEmailPercentage" },
  { label: "Download Link", key: "resultFileLink" },
];
