import {
  Row,
  Col,
  Typography,
  Flex,
  Divider,
  Progress,
  Button,
  Image,
  Skeleton,
} from "antd";
import { formatNumberWithComma } from "../../../utils/helpers";
import Spinner from "../../Spinner/Spinner";
import {
  ICDownloadOutlined,
  ICExclamationCircleOutlined,
} from "../../list/ListIcon";
import processBulkValidate from "../../../assets/images/validateProcess.svg";
import { ModalDownloadBulk } from "../../modal/ModalDownloadBulk";

const { Text } = Typography;

export const DetailResult = ({
  detailData,
  progressData,
  validated,
  invalidated,
  progressState,
  FinishState,
  radioDownload,
  setRadioDownload,
  openModalDownload,
  setOpenModalDownload,
}) => {
  const ResultList = ({ title, value, color, isPercentage }) => (
    <Col xl={8} lg={8} md={8} sm={8} xs={24}>
      <div className={`bg-white ${color ? `text-${color}` : ""}`}>
        <span className="py-1 text-secondary">{title}</span>
        <h5 className="mb-0 mt-0 text-3xl font-medium">
          {isPercentage ? `${value}%` : formatNumberWithComma(value)}
        </h5>
        <Divider className="my-2 mb-2 mt-2" />
      </div>
    </Col>
  );

  const ResultSuccess = ({ totalValue, percentage, title }) => (
    <Col xl={12} lg={12} md={12} sm={24} xs={24}>
      <Flex justify="space-between">
        <Text className="font-medium">{title}</Text>
        <Text>
          <span className="font-medium pr-1">
            {formatNumberWithComma(totalValue)}
          </span>
          <span
            className={
              title === "Valid Email" ||
              title === "Possibly Valid Email" ||
              title === "Valid Domain"
                ? "text-success"
                : title === "Professional Email" || title === "Disposable Email"
                ? "text-info"
                : title === "Free Email" || title === "Risky Email"
                ? "text-warning"
                : "text-danger"
            }
          >
            ({percentage}%)
          </span>
        </Text>
      </Flex>
      <Progress
        percent={percentage}
        showInfo={false}
        strokeColor={
          title === "Invalid Email" || title === "Invalid Domain"
            ? "#FF4D4F"
            : title === "Free Email" || title === "Risky Email"
            ? "#FAAD14"
            : percentage === "100.00"
            ? "#0D9F3F"
            : undefined
        }
        className="mb-4"
      />
    </Col>
  );

  const ResultProcess = () => (
    <Col span={24}>
      <Flex vertical justify="center" align="center">
        <Image src={processBulkValidate} preview={false} />
        <Text className="font-medium text-xl">
          <span className="pr-1">Processing</span>
          <Spinner />
        </Text>
        <Text className="text-secondary font-medium text-xs pb-3">
          Validating {progressData?.totalValidated}/
          {progressData?.actualRecords}
        </Text>
        <Progress percent={progressData?.progress} status="active" />
        <Divider className="my-2" />
      </Flex>
    </Col>
  );

  const ResultEmpty = () => (
    <Col span={24}>
      <Flex vertical justify="center" align="center" className="pb-4">
        <span className="color-secondary-25 pb-3 pt-6">
          <ICExclamationCircleOutlined className="custom" />
        </span>
        <Text className="color-secondary-25 text-center">
          Data belum tersedia.
        </Text>
        <Text className="color-secondary-25 pb-3 text-center">
          Silakan mulai verifikasi untuk menampilkan data.
        </Text>
        <Divider className="my-2" />
      </Flex>
    </Col>
  );

  return (
    <>
      <Row justify="center">
        <Col className="py-3">
          <Flex vertical>
            <Text className="font-medium text-xl text-center">
              Validation Results
            </Text>
            <Text className="text-xs text-center text-secondary">
              <span className="font-medium">Date added:</span>{" "}
              {detailData?.createdAt}
            </Text>
          </Flex>
        </Col>
      </Row>
      <Row justify="center" gutter={24} className="px-4 text-center">
        {!progressData && progressState ? (
          <Skeleton
            active
            title={false}
            paragraph={{ rows: 6 }}
            className="px-6"
          />
        ) : (
          <>
            <ResultList
              title="Unvalidated"
              value={
                progressState
                  ? progressData?.actualRecords - progressData?.totalValidated
                  : detailData?.details?.actualRecords -
                    detailData?.details?.totalValidated
              }
            />
            <ResultList
              title="Validated"
              value={
                progressState
                  ? progressData?.totalValidated
                  : detailData?.details?.totalValidated
              }
              color="success"
            />
            <ResultList
              title="Progress"
              value={
                progressState
                  ? progressData?.progress
                  : detailData?.details?.progress
              }
              isPercentage
            />
          </>
        )}
      </Row>
      <Row gutter={32} className="pt-6">
        {validated ? (
          <>
            {FinishState ? (
              <Skeleton
                active
                title={false}
                paragraph={{ rows: 6 }}
                className="px-6"
              />
            ) : (
              <>
                <ResultSuccess
                  totalValue={detailData?.details?.totalValidEmail}
                  percentage={detailData?.percentage?.validEmail}
                  title={`${
                    detailData?.type === "advance"
                      ? "Valid Email"
                      : "Possibly Valid Email"
                  }`}
                />
                <ResultSuccess
                  totalValue={detailData?.details?.totalInvalidEmail}
                  percentage={detailData?.percentage?.invalidEmail}
                  title="Invalid Email"
                />
                <ResultSuccess
                  totalValue={detailData?.details?.totalValidDomain}
                  percentage={detailData?.percentage?.validDomain}
                  title="Valid Domain"
                />
                <ResultSuccess
                  totalValue={detailData?.details?.totalInvalidDomain}
                  percentage={detailData?.percentage?.invalidDomain}
                  title="Invalid Domain"
                />
                <ResultSuccess
                  totalValue={detailData?.details?.totalProfesionalEmail}
                  percentage={detailData?.percentage?.profesionalEmail}
                  title="Professional Email"
                />
                <ResultSuccess
                  totalValue={detailData?.details?.totalRisky}
                  percentage={detailData?.percentage?.riskyEmail}
                  title="Risky Email"
                />
                <ResultSuccess
                  totalValue={detailData?.details?.totalDisposable}
                  percentage={detailData?.percentage?.disposableEmail}
                  title="Disposable Email"
                />
                <ResultSuccess
                  totalValue={detailData?.details?.totalFreeEmail}
                  percentage={detailData?.percentage?.freeEmail}
                  title="Free Email"
                />
                <Col span={24}>
                  <Button
                    icon={<ICDownloadOutlined />}
                    className="w-full mt-7 mb-1"
                    type="primary"
                    onClick={() => setOpenModalDownload(true)}
                  >
                    Download Result
                  </Button>
                  <Divider className="my-2" />
                </Col>
              </>
            )}
          </>
        ) : invalidated ? (
          <ResultEmpty />
        ) : !progressData && progressState ? (
          <Skeleton
            active
            title={false}
            paragraph={{ rows: 6 }}
            className="px-6"
          />
        ) : (
          <ResultProcess />
        )}
      </Row>
      <ModalDownloadBulk
        openModalDownload={openModalDownload}
        setOpenModalDownload={setOpenModalDownload}
        radioDownload={radioDownload}
        setRadioDownload={setRadioDownload}
        detailData={detailData}
      />
    </>
  );
};
