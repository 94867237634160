import { Row, Col, Flex, Divider, Button, Alert, Typography } from "antd";
import Spinner from "../../Spinner/Spinner";
import { formatNumberWithComma } from "../../../utils/helpers";
import { PaperClipOutlined } from "@ant-design/icons";
import { startBulkValidation } from "../../../api";
import ModalConfirm from "../../modal/ModalConfirm";
import { RadioValidate } from "../../radio/RadioButton";

const { Text } = Typography;

export const DetailCount = ({ detailData, props }) => {
  const DetailListCount = ({
    title,
    value,
    color,
    isUpperCase,
    isFile,
    fileName,
  }) => (
    <Col xl={12} lg={12} md={12} sm={12} xs={24}>
      <div className={`bg-white ${color ? `text-${color}` : ""}`}>
        <span className="py-1 text-secondary">{title}</span>
        <h5
          className={`mb-0 mt-0 text-3xl font-medium ${
            isUpperCase ? "text-uppercase" : ""
          }`}
        >
          {value}
        </h5>
        {isFile && (
          <span className="text-secondary text-2xs">
            {title === "Source File" && <PaperClipOutlined />}
            {fileName}
          </span>
        )}
        <Divider className="my-2 mb-2 mt-2" />
      </div>
    </Col>
  );

  const {
    lowQuota,
    notEnoughQuota,
    runningOutQuota,
    invalidated,
    getProgressBulk,
    getDetail,
    setProgressState,
    radioValue,
    setOpenModalInfo,
  } = props;

  const handleStart = async () => {
    const response = await startBulkValidation({
      uuid: detailData?.uuid,
      type: radioValue,
    });
    if (response.statusCode === 400) {
      setTimeout(() => {
        ModalConfirm({
          title: "Gagal melakukan validasi email",
          customMessage: (
            <span className="text-sm">
              Validasi email gagal dilakukan, karena anda tidak memiliki
              <br /> kuota validasi yang cukup untuk melakukan validasi. Silakan
              <br /> dapat melakukan penambahan kuota.
            </span>
          ),
          type: "primary",
          width: 500,
          okText: "Oke",
          cancelText: "Tambah Kuota",
          href: "https://www.aktiva.co.id/email-validator-order/",
          target: "_blank",
        });
      }, 1000);
    } else {
      setTimeout(() => {
        setProgressState(true);
        getDetail();
        getProgressBulk();
      }, 1000);
    }
  };

  return (
    <>
      {!detailData ? (
        <Flex justify="center" align="center" className="h-screen-360">
          <Spinner />
        </Flex>
      ) : (
        <>
          <Row gutter={24}>
            <DetailListCount
              title="Total Email"
              value={formatNumberWithComma(detailData?.details?.totalRecords)}
            />
            <DetailListCount
              title="Duplicate Email"
              value={formatNumberWithComma(
                detailData?.details?.totalDuplicateRecords
              )}
              color="warning"
            />
            <DetailListCount
              title="Actual Email"
              value={formatNumberWithComma(detailData?.details?.actualRecords)}
              color="success"
              isFile
              fileName={
                <span>
                  <span className="font-medium">
                    {detailData?.details?.totalDuplicateRecords} emails
                  </span>{" "}
                  will not checked
                </span>
              }
            />
            <DetailListCount
              title="Source File"
              value={detailData?.fileFormat}
              isUpperCase
              isFile
              fileName={`${detailData?.fileName}`}
            />
            <Col span={24}>
              <Flex vertical align="center">
                <div className="pt-1 pb-2">
                  <RadioValidate
                    props={props}
                    typeValidate={detailData?.type}
                  />
                </div>
                <Button
                  className="w-full my-1"
                  type="primary"
                  disabled={!invalidated || !radioValue}
                  onClick={() => handleStart()}
                >
                  Mulai Verifikasi
                </Button>
                {invalidated && (
                  <div className="text-secondary text-xs text-center w-full">
                    Verifikasi ini akan mengurangi{" "}
                    <span className="font-medium">
                      {formatNumberWithComma(
                        detailData?.details?.actualRecords
                      )}{" "}
                      kuota
                    </span>{" "}
                    anda
                  </div>
                )}
                <Divider className="my-2 mb-2 mt-2" />
                <Text
                  className="text-info link-text text-2xs"
                  onClick={() => setOpenModalInfo(true)}
                >
                  Apa itu Basic & Advanced Validation?
                </Text>
                {(lowQuota || notEnoughQuota || runningOutQuota) && (
                  <Alert
                    message={
                      lowQuota
                        ? "Kuota validasi email anda akan habis, segera lakukan penambahan kuota"
                        : notEnoughQuota || runningOutQuota
                        ? "Kuota validasi email anda habis, harap lakukan penambahan kuota"
                        : undefined
                    }
                    type={
                      lowQuota
                        ? "warning"
                        : notEnoughQuota || runningOutQuota
                        ? "error"
                        : ""
                    }
                    showIcon
                  />
                )}
              </Flex>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};
