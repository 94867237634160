import { Button, Dropdown, Menu } from "antd";
import { LogoAktiva } from "../Components";
import { ICPoweroffOutlined, ICUserOutlined } from "../list/ListIcon";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { handleLogout } from "../../utils/handleFunction";
import "./headersection.less";

const HeaderSection = ({ title }) => {
  const user = useSelector(({ userData }) => userData.value);

  const companyName = (
    <span className="text-success pr-3 text-sm">
      PT Aktiva Kreasi Investama
    </span>
  );
  let actionView = companyName;

  const userDropdown = (
    <Menu
      items={[
        {
          key: "1",
          icon: <ICPoweroffOutlined className="text-danger" rotate={90} />,
          label: (
            <Button type="link" className="p-0 text-danger">
              Keluar
            </Button>
          ),
          onClick: () => handleLogout(),
        },
      ]}
    />
  );

  if (user)
    actionView = (
      <Dropdown
        overlay={userDropdown}
        trigger={["click"]}
        placement="bottomRight"
        overlayClassName="dropdown-header "
        overlayStyle={{ minWidth: "min-content" }}
      >
        <Button
          type="link"
          className="text-success p-0 text-sm"
          icon={<ICUserOutlined />}
        >
          <span className="text-success pr-3 text-sm">{user?.data?.name}</span>
        </Button>
      </Dropdown>
    );

  return (
    <header
      className={`flex items-center justify-between ${
        title === "header-flow" ? "header-flow" : "header-setup"
      } `}
    >
      <Link to="/main">
        <LogoAktiva className="aktiva-logo-setup" />
      </Link>
      {!title && actionView}
    </header>
  );
};

export default HeaderSection;
