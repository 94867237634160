import { Modal, Form, Input, Row, Col, Flex } from "antd";
import { ICExclamationCircleOutlined } from "../list/ListIcon";
import { confirmDeletionRule, gatherFormData } from "../../utils/helpers";
import ModalSuccess from "./ModalSuccess";
import ModalWarning from "./ModalWarning";
import { useState } from "react";
import { deleteApiKey } from "../../api";

export const ModalDeleteBulk = ({
  open,
  setOpen,
  form,
  handleInput,
  loading,
}) => {
  return (
    <Modal
      centered
      open={open}
      closeIcon={false}
      width={600}
      className="delete-modal"
      children={
        <Row>
          <Flex gap={16}>
            <Col className="warning-icon">
              <ICExclamationCircleOutlined className="text-warning" />
            </Col>
            <Col>
              <span className="font-medium text-base">
                Hapus data list email
              </span>
              <p className="my-0 pt-2">
                Apakah anda yakin ingin menghapus data list tersebut?
                <br />
                <br /> Seluruh data pada list ini akan terhapus (termasuk hasil
                validasi) dan tidak dapat dikembalikan kembali. Ketik{" "}
                <span className="font-medium">confirm</span> untuk menghapus
                data
              </p>
              <Form form={form} className="flex flex-col gap-y-2 pt-2">
                <Form.Item
                  className="mb-0"
                  name="confirmation"
                  validateFirst
                  rules={[{ validator: confirmDeletionRule }]}
                >
                  <Input placeholder="confirm" />
                </Form.Item>
              </Form>
            </Col>
          </Flex>
        </Row>
      }
      onCancel={() => {
        form.resetFields();
        setOpen(false);
      }}
      okText="Ya Hapus"
      cancelText="Batalkan"
      okButtonProps={{ type: "danger" }}
      onOk={() => gatherFormData(form, handleInput)}
      confirmLoading={loading}
    />
  );
};

export const ModalDeleteKey = ({
  open,
  setOpen,
  form,
  rowValue,
  getListApiKey,
}) => {
  const [loading, setLoading] = useState(false);

  const handleInput = async () => {
    const confirmValue = form.getFieldValue(["confirmation"]);
    if (confirmValue === "confirm") {
      setLoading(true);
      const { statusCode } = await deleteApiKey({ uuid: rowValue.uuid });
      if (statusCode === 200) {
        setLoading(false);
        ModalSuccess({
          title: "API key berhasil dihapus",
          customMessage: (
            <span>
              API key <span className="font-medium">{rowValue.name}</span>{" "}
              berhasil dihapus dari sistem
            </span>
          ),
          width: 500,
          type: "primary",
          onOk: () => {
            form.resetFields();
            setOpen(false);
            getListApiKey();
          },
        });
      } else {
        setLoading(false);
        ModalWarning({
          title: "Gagal menghapus API key",
          customMessage: (
            <span>
              API key gagal dihapus dari sistem. Terdapat kendala pada
              <br /> sistem, harap coba lagi
            </span>
          ),
          width: 500,
          type: "primary",
          onOk: () => {
            form.resetFields();
            setOpen(false);
          },
        });
      }
    }
  };

  return (
    <Modal
      centered
      open={open}
      closeIcon={false}
      width={600}
      className="delete-modal"
      children={
        <Row>
          <Flex gap={16}>
            <Col className="warning-icon">
              <ICExclamationCircleOutlined className="text-warning" />
            </Col>
            <Col>
              <span className="font-medium text-base">Hapus API key</span>
              <p className="my-0 pt-2">
                Apakah anda yakin ingin menghapus API key{" "}
                <span className="font-medium">{rowValue.name}</span>?
                <br />
                <br /> Penggunaan API key tersebut pada aplikasi anda akan tidak
                dapat digunakan lagi. Proses ini tidak dapat dikembalikan
                kembali. Ketik <span className="font-medium">confirm</span>{" "}
                untuk menghapus data
              </p>
              <Form form={form} className="flex flex-col gap-y-2 pt-2">
                <Form.Item
                  className="mb-0"
                  name="confirmation"
                  validateFirst
                  rules={[{ validator: confirmDeletionRule }]}
                >
                  <Input placeholder="confirm" />
                </Form.Item>
              </Form>
            </Col>
          </Flex>
        </Row>
      }
      onCancel={() => {
        form.resetFields();
        setOpen(false);
      }}
      okText="Ya Hapus"
      cancelText="Batalkan"
      okButtonProps={{ type: "danger" }}
      onOk={() => gatherFormData(form, handleInput)}
      confirmLoading={loading}
    />
  );
};
