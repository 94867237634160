import { Typography, Form, Input, Upload, Button } from "antd";
import { validateFileBulk } from "../../utils/helpers";
import { ICUploadOutlined } from "../list/ListIcon";

const { Text } = Typography;

export const FormUploadBulk = ({
  setListName,
  setFileBulk,
  setFileValidation,
  props,
  form,
}) => {
  return (
    <Form form={form} layout="vertical" className="flex flex-col gap-y-3">
      <Text>
        Upload file berisi list alamat email yang ingin dilakukan validasi. File
        yang didukung untuk pengecekkan berformat CSV dan TXT. Download sample
        file tersebut{" "}
        <a href="https://www.aktiva.co.id/wp-content/uploads/2024/02/sample-email-aktiva-validator.csv">
          disini
        </a>
        <br />
        <span className="text-secondary italic">
          (kolom alamat email wajib tersedia)
        </span>
      </Text>
      <Form.Item
        label="Nama List"
        name="listName"
        className="right-required mb-0"
        onChange={(e) => setListName(e.target.value)}
      >
        <Input placeholder="ex.: List Email Customer"></Input>
      </Form.Item>
      <Form.Item
        name="fileBulk"
        valuePropName="fileList"
        label="Upload file list client (.csv or .txt)"
        getValueFromEvent={(e) => {
          setFileBulk(e.fileList[0]);
          const file = e.fileList[0];
          if (file) {
            const result = validateFileBulk({ file });
            setFileValidation(result);
          } else {
            setFileValidation(false);
          }
        }}
        className="right-required mb-0"
      >
        <Upload {...props} accept=".csv, .txt">
          <Button icon={<ICUploadOutlined />}>Click to Upload</Button>
        </Upload>
      </Form.Item>
      <Text className="text-xs text-secondary">
        Maksimal ukuran file yang diperbolehkan sebesar 200KB
      </Text>
    </Form>
  );
};
