import {
  ConfigProvider,
  Dropdown,
  Table,
  Flex,
  Button,
  Badge,
  Tag,
  Grid,
} from "antd";
import { TableTitle } from "../../table-title/TableTitle";
import { emptyHistoryValidation } from "../../empty/EmptyComponent";
import { ICDownOutlined } from "../../list/ListIcon";
import { ActionTableBulk } from "../../ActionTable";
import Spinner from "../../Spinner/Spinner";
import { formatNumberWithComma, splitDate } from "../../../utils/helpers";

const { useBreakpoint } = Grid;

export const TableBulkValidate = ({
  dataValidate,
  currentPage,
  currentLimit,
  searchValue,
  showSpinner,
  filterBulk,
  setFilterBulk,
  handleSearch,
  changeSearchQuery,
  changePage,
  changePageLimit,
  refreshListBulk,
  refreshSummaryBulk,
  openModalUploadBulk,
}) => {
  const screens = useBreakpoint();
  const XLargeScreen = screens.xl;
  const columnBulk = [
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "Date",
      width: 116,
      render: (data) => {
        const { date, time } = splitDate(data);
        return (
          <div>
            <p className="mb-0 mt-0">{date}</p>
            <p className="mb-0 mt-0 text-secondary text-xs" type="secondary">
              {time}
            </p>
          </div>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "Name",
      ellipsis: true,
      ...(!XLargeScreen ? { width: 368 } : {}),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "Status",
      width: 140,
      render: (status) => {
        return (
          <Badge
            status={
              status === "validated"
                ? "success"
                : status === "not-validated"
                ? "default"
                : "warning"
            }
            text={
              status === "validated"
                ? "Complete"
                : status === "not-validated"
                ? "Not Validated"
                : "On Process"
            }
            className="font-medium"
          />
        );
      },
    },
    {
      title: "Source",
      dataIndex: "fileFormat",
      key: "Source",
      width: 80,

      render: (fileFormat) => {
        return (
          <Tag className="mr-0">
            {fileFormat === "txt" ? (
              <div className="font-medium">
                <span>TXT</span>
              </div>
            ) : (
              <div className="font-medium">
                <span>CSV</span>
              </div>
            )}
          </Tag>
        );
      },
    },
    {
      title: "Actual Record",
      dataIndex: "actualRecord",
      key: "Actual Record",
      align: "right",
      width: 112,
      render: (actualRecord) => {
        return <span>{formatNumberWithComma(actualRecord)}</span>;
      },
    },
    {
      title: "Valid Email",
      key: "Valid Email",
      align: "right",
      width: 92,

      render: ({ validEmail, validEmailPercentage }) => {
        return (
          <Flex vertical>
            <span className="text-success">
              {formatNumberWithComma(validEmail)}
            </span>
            <span className="text-secondary text-2xs">
              {validEmailPercentage}%
            </span>
          </Flex>
        );
      },
    },
    {
      title: "Invalid Email",
      key: "Invalid Email",
      align: "right",
      width: 92,
      render: ({ invalidEmail, invalidEmailPercentage }) => {
        return (
          <Flex vertical>
            <span className="text-danger">
              {formatNumberWithComma(invalidEmail)}
            </span>
            <span className="text-secondary text-2xs">
              {invalidEmailPercentage}%
            </span>
          </Flex>
        );
      },
    },
    {
      key: "actions",
      fixed: "right",
      width: 120,
      style: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        flex: "1 0 0",
        alignSelf: "scretch",
      },
      render: (_, record) => (
        <Dropdown
          trigger={["click"]}
          overlay={() => (
            <ActionTableBulk
              record={record}
              refreshListBulk={refreshListBulk}
              refreshSummaryBulk={refreshSummaryBulk}
            />
          )}
        >
          <Button>
            Action
            <ICDownOutlined />
          </Button>
        </Dropdown>
      ),
    },
  ];

  return (
    <ConfigProvider
      renderEmpty={() => {
        if (dataValidate?.data?.rows && dataValidate.data.rows.length === 0) {
          return emptyHistoryValidation({
            page: "Bulk Page",
            title: "Data tidak ditemukan",
            message: (
              <span className="max-w-400">
                Data list yang anda cari tidak ditemukan, pastikan tidak ada
                salah ketik pada penulisan
              </span>
            ),
          });
        } else {
          return emptyHistoryValidation({
            page: "Bulk Page",
            title: "Data belum tersedia",
            message: (
              <span className="max-w-400">
                Anda belum melakukan validasi email bulk, silakan menambahkan
                list untuk melihat data
              </span>
            ),
          });
        }
      }}
    >
      <div className="flex flex-col">
        <TableTitle
          title="Bulk Validation List"
          description="List bulk validation yang sudah diupload dan validasi"
          dataJSON={dataValidate}
          showSpinner={showSpinner}
          searchValue={searchValue}
          handleSearch={handleSearch}
          changeSearchQuery={changeSearchQuery}
          changePageLimit={changePageLimit}
          refreshListBulk={refreshListBulk}
          refreshSummaryBulk={refreshSummaryBulk}
          openModalUploadBulk={openModalUploadBulk}
        />
        {!dataValidate ? (
          <Flex justify="center" align="center" style={{ height: "50vh" }}>
            <Spinner />
          </Flex>
        ) : (
          <Table
            {...(!XLargeScreen && { scroll: { x: 1024 } })}
            columns={columnBulk}
            dataSource={dataValidate?.data?.rows}
            pageLimit={{
              currentLimit: setFilterBulk.limit,
              onSelectLimit: (limit) => {
                setFilterBulk({
                  ...filterBulk,
                  page: 1,
                  limit,
                });
              },
              disable: dataValidate?.data?.page?.totalCount < 10,
            }}
            pagination={{
              pageSize: currentLimit,
              current: currentPage,
              total: dataValidate?.data?.page?.totalCount,
              showSizeChanger: false,
              onChange: changePage,
            }}
            loading={showSpinner}
          />
        )}
      </div>
    </ConfigProvider>
  );
};
