import { Layout } from "antd";
import { ICMenuFoldOutlined } from "../../components/list/ListIcon";
import UserNavbar from "../../components/navbar/UserNavbar";
import Sidebar from "../../components/sidebar/Sidebar";
import { useState } from "react";
import { Outlet } from "react-router-dom";
import "./dashboard.less";
import Spinner from "../../components/Spinner/Spinner";

const { Header, Sider, Content } = Layout;

const Dashboard = ({ user }) => {
  const [toggle, setToggle] = useState(false);

  if (!user) return <Spinner full />;

  return (
    <div className="dashboard-wrapper">
      <Layout>
        <Header className="header-dashboard">
          <UserNavbar />
        </Header>
        <Layout>
          <Sider
            className="dashboard-sider"
            breakpoint="lg"
            width={240}
            collapsible
            trigger={<ICMenuFoldOutlined />}
            theme="light"
            collapsed={toggle}
            onCollapse={(collapsed) => setToggle(collapsed)}
          >
            <Sidebar />
          </Sider>
          <Layout className="dashboard-layout">
            <Content>
              <Outlet />
            </Content>
          </Layout>
        </Layout>
      </Layout>
    </div>
  );
};

export default Dashboard;
