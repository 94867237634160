import { Result, Button, Flex } from "antd";
import useDocumentTitle from "../../../../utils/useDocumentTitle";

const VerifikasiSuccess = ({ claimQuota, title }) => {
  useDocumentTitle(title);
  return (
    <Result
      className="finish-result"
      status="success"
      title={<span className="font-medium">Pendaftaran Akun Selesai</span>}
      subTitle={
        claimQuota ? (
          <span>
            Selamat, akun Anda berhasil didaftarkan. Selanjutnya silakan
            lengkapi data perusahaan untuk dapat melakukan
            <br /> verifikasi email dengan kuota yang sudah dimiliki.
          </span>
        ) : (
          <span>
            Selamat, akun Anda berhasil didaftarkan. Anda dapat langsung explore
            dengan mengakses dashboard, atau
            <br /> melengkapi data perusahaan terlebih dahulu.
          </span>
        )
      }
      extra={[
        <Flex vertical align="center" gap={12}>
          <Button
            type="primary"
            className="btn-setup mt-2"
            onClick={() => {
              if (claimQuota) {
                window.location.href = "/register/company-data";
              } else {
                window.location.href = "/main";
              }
            }}
          >
            {claimQuota ? "Mulai Lengkapi Data" : "Akses Dashboard"}
          </Button>
          {!claimQuota && (
            <Button
              type="link"
              className="btn-setup"
              onClick={() => (window.location.href = "/register/company-data")}
            >
              Lengkapi Data Perusahaan
            </Button>
          )}
        </Flex>,
      ]}
    />
  );
};

export default VerifikasiSuccess;
