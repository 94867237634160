import { UserOutlined } from "@ant-design/icons";
import { Button, Dropdown, Flex } from "antd";
import logoDark from "../../assets/images/aktiva-dark.png";
import "./usernavbar.less";
import { useSelector } from "react-redux";
import { QuotaDropdown, UserDropdown } from "../Dropdown";
import { ICQuestionCircleOutlined } from "../list/ListIcon";
import { DatabaseOutlined } from "@ant-design/icons";
import { formatNumberWithDot } from "../../utils/helpers";

const UserNavbar = () => {
  const user = useSelector(({ userData }) => userData.value);
  const userName = user?.data?.name;
  const userEmail = user?.data?.email;
  const companyUUID = user?.data?.companyUuid;
  const totalBasicQuota = user?.data?.basicQuotaDetail?.totalQuota;
  const quotaBasicUsed = user?.data?.basicQuotaDetail?.quotaUsage;
  const percentageBasicUsed = user?.data?.basicQuotaDetail?.usagePercentage;
  const totalAdvancedQuota = user?.data?.advanceQuotaDetail?.totalQuota;
  const quotaAdvancedUsed = user?.data?.advanceQuotaDetail?.quotaUsage;
  const percentageAdvancedUsed =
    user?.data?.advanceQuotaDetail?.usagePercentage;
  const isClaimQuota = user?.data?.isClaimQuota;
  const creditRemain = user?.data?.totalQuotaAvailable;

  return (
    <div className="flex justify-between">
      <div className="flex items-center">
        <img src={logoDark} alt="logo" />
      </div>
      <div className="flex justify-between items-center">
        <Dropdown
          overlay={
            <QuotaDropdown
              totalBasicQuota={totalBasicQuota}
              quotaBasicUsed={quotaBasicUsed}
              percentageBasicUsed={percentageBasicUsed}
              totalAdvancedQuota={totalAdvancedQuota}
              quotaAdvancedUsed={quotaAdvancedUsed}
              percentageAdvancedUsed={percentageAdvancedUsed}
              isClaimQuota={isClaimQuota}
              creditRemain={creditRemain}
              companyUUID={companyUUID}
            />
          }
          placement="bottomRight"
          trigger={["click"]}
          overlayStyle={{ paddingTop: 16 }}
        >
          <Button
            icon={<DatabaseOutlined className="text-base" />}
            type="link"
            className="user-button flex items-center"
          >
            <Flex vertical>
              <span className="text-3xsC font-medium text-left">Credit</span>
              <span className="text-2xsC font-semibold text-left">
                {creditRemain ? formatNumberWithDot(creditRemain) : 0}
              </span>
            </Flex>
          </Button>
        </Dropdown>
        <Button
          type="dashed"
          target="_blank"
          href="https://support.aktiva.co.id"
          ghost
          icon={<ICQuestionCircleOutlined />}
        >
          Support Ticket
        </Button>
        <div className="dropdown-user">
          <Dropdown
            overlay={<UserDropdown userName={userName} userEmail={userEmail} />}
            placement="bottomRight"
            trigger={["click"]}
            className="notification-icon"
            overlayStyle={{ paddingTop: 16 }}
          >
            <Button
              icon={<UserOutlined className="text-base" />}
              type="link"
              className="user-button"
            >
              {userName}
            </Button>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default UserNavbar;
