import DashboardHeader from "../../../../components/dashboard-header/DashboardHeader";
import useDocumentTitle from "../../../../utils/useDocumentTitle";
import { Row, Col, Card, Form } from "antd";
import { SummaryValidate } from "../../../../components/card/bulk/SummaryValidate";
import { TableBulkValidate } from "../../../../components/table/bulk/TableBulkValidate";
import { useCallback, useEffect, useState } from "react";
import { ModalUploadBulk } from "../../../../components/modal/ModalUploadBulk";
import { bulkList, bulkSummary } from "../../../../api";

const BulkValidation = () => {
  useDocumentTitle("Bulk Validation");
  const [form] = Form.useForm();
  const [modalUploadBulk, setModalUploadBulk] = useState(false);
  const [filterBulk, setFilterBulk] = useState({
    page: 1,
    limit: 10,
  });
  const [currentPage, setCurrentPage] = useState(filterBulk.page);
  const [currentLimit, setCurrentLimit] = useState("10");
  const [searchValue, setSearchValue] = useState("");
  const [showSkeleton, setShowSkeleton] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [dataValidate, setDataValidate] = useState(null);
  const [summaryValidate, setSummaryValidate] = useState(null);
  const [listName, setListName] = useState(null);
  const [fileBulk, setFileBulk] = useState(null);
  const [fileValidation, setFileValidation] = useState(false);
  const [process, setProcess] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const getListBulkValidate = useCallback(async () => {
    setShowSpinner(true);
    const { data, statusCode } = await bulkList(filterBulk);
    setDataValidate({ data, statusCode });
    setShowSpinner(false);
  }, [filterBulk]);

  const getSummaryBulkValidate = useCallback(async () => {
    setShowSkeleton(true);
    const { data, statusCode } = await bulkSummary();
    setSummaryValidate({ data, statusCode });
    setShowSkeleton(false);
  }, []);

  useEffect(() => {
    getListBulkValidate();
  }, [getListBulkValidate]);

  useEffect(() => {
    getSummaryBulkValidate();
  }, [getSummaryBulkValidate]);

  const openModalUploadBulk = () => {
    setModalUploadBulk(true);
  };

  const closeModalUploadBulk = (setup) => {
    setModalUploadBulk(false);
    form.resetFields();
    setListName(null);
    setFileBulk(null);
    setFileValidation(false);
    setProcess(false);
    setSuccess(false);
    setError(false);
    if (setup === "afterProcess") {
      getListBulkValidate();
      getSummaryBulkValidate();
    }
  };

  const changePage = (page) => {
    setCurrentPage(page);
    setFilterBulk({
      ...filterBulk,
      page,
    });
  };

  const changePageLimit = (value) => {
    setCurrentLimit(value);
    setCurrentPage(1);
    setFilterBulk({
      ...filterBulk,
      page: 1,
      limit: value,
    });
  };

  const handleSearch = (value) => {
    setSearchValue(value);
  };

  const changeSearchQuery = (query) => {
    setCurrentPage(1);
    setFilterBulk({
      ...filterBulk,
      page: 1,
      name: query,
    });
  };

  return (
    <>
      <DashboardHeader title="Bulk Validation Email" />
      <Row>
        <Col span={24} className="px-6 py-3">
          <Card>
            <SummaryValidate
              openModalUploadBulk={openModalUploadBulk}
              showSkeleton={showSkeleton}
              summaryValidate={summaryValidate?.data}
            />
            <ModalUploadBulk
              open={modalUploadBulk}
              close={closeModalUploadBulk}
              setListName={setListName}
              setFileBulk={setFileBulk}
              listName={listName}
              fileBulk={fileBulk}
              setFileValidation={setFileValidation}
              fileValidation={fileValidation}
              form={form}
              setProcess={setProcess}
              setSuccess={setSuccess}
              setError={setError}
              process={process}
              success={success}
              error={error}
            />
          </Card>
        </Col>
        <Col span={24} className="px-6 pt-2">
          <Card>
            <TableBulkValidate
              dataValidate={dataValidate}
              currentPage={currentPage}
              currentLimit={currentLimit}
              searchValue={searchValue}
              showSpinner={showSpinner}
              setFilterBulk={setFilterBulk}
              filterBulk={filterBulk}
              handleSearch={handleSearch}
              changeSearchQuery={changeSearchQuery}
              changePage={changePage}
              changePageLimit={changePageLimit}
              refreshListBulk={getListBulkValidate}
              refreshSummaryBulk={getSummaryBulkValidate}
              openModalUploadBulk={openModalUploadBulk}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default BulkValidation;
