import { Card, Col, Flex, Row, Button, Form, Grid } from "antd";
import useDocumentTitle from "../../../utils/useDocumentTitle";
import {
  ProfileDetail,
  CompanyInformation,
  ContactInformation,
  DetailQuota,
} from "../../../components/card/profile/ProfileComp";
import "./profile.less";
import {
  ICCreditCardOutlined,
  ICEditOutlined,
  ICPlusOutlined,
} from "../../../components/list/ListIcon";
import { useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import {
  changeProfilePassword,
  companyInformation,
  contactInformation,
  quotaDetail,
  updateCompany,
  updateUser,
} from "../../../api";
import {
  ModalChangePassword,
  ModalInfoCompany,
  ModalInfoContact,
} from "../../../components/modal/ModalProfile";
import ModalSuccess from "../../../components/modal/ModalSuccess";
import ModalWarning from "../../../components/modal/ModalWarning";
import { gatherFormData } from "../../../utils/helpers";
import useGetUser from "../../../utils/hooks/useGetUser";

const { useBreakpoint } = Grid;

const Profile = () => {
  useDocumentTitle("Profile");
  const [dataQuota, setDataQuota] = useState(null);
  const [dataCompany, setDataCompany] = useState(null);
  const [dataContact, setDataContact] = useState(null);
  const [openModalChange, setOpenModalChange] = useState(false);
  const [openModalCompany, setOpenModalCompany] = useState(false);
  const [openModalContact, setOpenModalContact] = useState(false);
  const [isLoadingChange, setIsLoadingChange] = useState(false);
  const [isLoadingCompany, setIsLoadingCompany] = useState(false);
  const [isLoadingContact, setIsLoadingContact] = useState(false);
  const [disableBtnChange, setDisableBtnChange] = useState(true);
  const [password, setPassword] = useState(null);
  const [newPassword, setNewPassword] = useState(null);
  const [skeletonContact, setSkeletonContact] = useState(false);
  const [skeletonCompany, setSkeletonCompany] = useState(false);

  const [form_change] = Form.useForm();
  const [form_company] = Form.useForm();
  const [form_contact] = Form.useForm();
  const screen = useBreakpoint();
  const Xlargescreen = screen.xl;
  const Largescreen = screen.lg;
  const Mediumscreen = screen.md;
  const smallScreen = !(Xlargescreen || Largescreen || Mediumscreen);
  const user = useSelector(({ userData }) => userData.value);
  const { refreshUser } = useGetUser();

  const noDataCompany =
    (dataCompany?.data?.name &&
      dataCompany?.data?.address &&
      dataCompany?.data?.phone &&
      dataCompany?.data?.website) === "";

  const getDetailQuota = useCallback(async () => {
    const { data, statusCode } = await quotaDetail();
    setDataQuota({ data, statusCode });
  }, []);

  const getCompanyInformation = useCallback(async () => {
    setSkeletonCompany(true);
    const { data, statusCode } = await companyInformation();
    setDataCompany({ data, statusCode });
    setSkeletonCompany(false);
  }, []);

  const getContactInformation = useCallback(async () => {
    setSkeletonContact(true);
    const { data, statusCode } = await contactInformation();
    setDataContact({ data, statusCode });
    setSkeletonContact(false);
  }, []);

  useEffect(() => {
    if (!dataCompany) getCompanyInformation();
  }, [getCompanyInformation, dataCompany]);

  useEffect(() => {
    if (!dataQuota) getDetailQuota();
  }, [getDetailQuota, dataQuota]);

  useEffect(() => {
    if (!dataContact) getContactInformation();
  }, [getContactInformation, dataContact]);

  const handleCloseModalChange = () => {
    setOpenModalChange(false);
    form_change.resetFields();
    setDisableBtnChange(true);
  };

  const handleCloseModalCompany = () => {
    setOpenModalCompany(false);
    form_company.resetFields();
  };

  const handleCloseModalContact = () => {
    setOpenModalContact(false);
    form_contact.resetFields();
  };

  const handleValidatePassword = (e) => {
    const getPassword = e.target.value;
    setPassword(getPassword);
    setDisableBtnChange(!(getPassword === newPassword));
  };

  const handleValidateRepeatPassword = (e) => {
    const getRepeatPassword = e.target.value;
    setNewPassword(getRepeatPassword);
    setDisableBtnChange(!(password === getRepeatPassword));
  };

  const handleFormChange = async () => {
    setIsLoadingChange(true);
    const values = form_change.getFieldValue();
    const responseChange = await changeProfilePassword({
      oldPassword: values.current_password,
      password: values.password,
      passwordConfirm: values.new_password,
    });
    setIsLoadingChange(false);
    if (responseChange.statusCode === 200) {
      ModalSuccess({
        title: "Password berhasil diperbarui",
        customMessage: "Password anda berhasil diperbarui pada sistem",
        type: "primary",
        width: 416,
        onOk: () => handleCloseModalChange(),
      });
    } else {
      ModalWarning({
        title: "Password Tidak Sesuai",
        customMessage:
          "Password saat ini yang anda masukkan salah, silakan dapat memasukkan password yang benar",
        type: "primary",
        width: 416,
      });
    }
  };

  const handleFormCompany = async () => {
    const values = form_company.getFieldValue();
    if (
      values.companyName &&
      values.phone &&
      values.address &&
      values.website
    ) {
      setIsLoadingCompany(true);
      const responseCompany = await updateCompany({
        name: values.companyName,
        phone: values.phone,
        address: values.address,
        website: values.website,
      });
      setIsLoadingCompany(false);
      if (responseCompany.statusCode === 200) {
        ModalSuccess({
          title: "Informasi Perusahaan berhasil diupdate",
          customMessage: `Informasi Perusahaan ${dataCompany?.data?.name} berhasil diupdate pada sistem`,
          type: "primary",
          width: 416,
          onOk: () => {
            handleCloseModalCompany();
            getCompanyInformation();
          },
        });
      } else {
        ModalWarning({
          title: "Informasi Perusahaan gagal diupdate",
          customMessage:
            "Terdapat kendala pada sistem. Harap dapat mencoba kembali atau dapat hubungi Tim Support kami melalui Support Ticket.",
          type: "primary",
          width: 416,
        });
      }
    } else {
      gatherFormData(form_company);
    }
  };

  const handleFormContact = async () => {
    const values = form_contact.getFieldValue();
    if (values.name && values.phone) {
      setIsLoadingContact(true);
      const responseContact = await updateUser({
        name: values.name,
        phone: values.phone,
      });
      setIsLoadingContact(false);
      if (responseContact.statusCode === 200) {
        ModalSuccess({
          title: "Kontak berhasil diupdate",
          customMessage:
            "Jika terdapat perubahan alamat email, silakan cek email tersebut untuk mengatur ulang password.",
          type: "primary",
          width: 416,
          onOk: () => {
            handleCloseModalContact();
            getContactInformation();
            refreshUser();
          },
        });
      } else {
        ModalWarning({
          title: "Kontak gagal diupdate",
          customMessage:
            "Terdapat kendala pada sistem. Harap dapat mencoba kembali atau dapat hubungi Tim Support kami melalui Support Ticket.",
          type: "primary",
          width: 416,
        });
      }
    } else {
      gatherFormData(form_contact);
    }
  };

  const propsUser = {
    name: user?.data?.name,
    email: user?.data?.email,
    companyUUID: user?.data?.companyUuid,
    setOpenModalChange,
    setOpenModalContact,
  };
  const propsCompany = {
    name: dataCompany?.data?.name,
    address: dataCompany?.data?.address,
    phone: dataCompany?.data?.phone,
    website: dataCompany?.data?.website,
  };

  const propsQuota = {
    basicQuotaUsage: dataQuota?.data?.basicQuotaDetail?.quotaUsage,
    basicQuotaTotal: dataQuota?.data?.basicQuotaDetail?.totalQuota,
    basicPrecentage: dataQuota?.data?.basicQuotaDetail?.usagePercentage,
    advanceQuotaUsage: dataQuota?.data?.advanceQuotaDetail?.quotaUsage,
    advanceQuotaTotal: dataQuota?.data?.advanceQuotaDetail?.totalQuota,
    advancePrecentage: dataQuota?.data?.advanceQuotaDetail?.usagePercentage,
    totalQuotaAvailable: dataQuota?.data?.totalQuotaAvailable,
    totalUsagePercentage: dataQuota?.data?.totalUsagePercentage,
    noQuota: dataQuota?.statusCode === 400,
    isClaimQuota: user?.data?.isClaimQuota,
  };

  const propsModal = {
    openModalChange,
    handleCloseModalChange,
    form_change,
    isLoadingChange,
    form_company,
    isLoadingCompany,
    form_contact,
    isLoadingContact,
    handleFormChange,
    handleFormCompany,
    handleFormContact,
    disableBtnChange,
    openModalCompany,
    handleCloseModalCompany,
    openModalContact,
    handleCloseModalContact,
    dataContact: dataContact?.data?.rows[0],
    dataCompany: dataCompany?.data,
    handleValidatePassword,
    handleValidateRepeatPassword,
  };

  return (
    <>
      <Row gutter={24} className="px-6 py-4 mr-0">
        <Col
          xl={6}
          lg={8}
          md={8}
          sm={24}
          xs={24}
          {...(smallScreen && { className: "pr-0" })}
        >
          <Card className="profile-pic" loading={!user}>
            <ProfileDetail propsUser={propsUser} />
            <ModalChangePassword propsModal={propsModal} />
            <ModalInfoContact propsModal={propsModal} />
          </Card>
        </Col>
        <Col xl={18} lg={16} md={16} sm={24} xs={24} className="pr-0">
          <Flex vertical gap={24}>
            <Card
              title="Informasi Perusahaan"
              extra={
                <Button
                  type="primary"
                  icon={<ICEditOutlined />}
                  onClick={() => setOpenModalCompany(true)}
                  disabled={noDataCompany}
                >
                  Edit Informasi
                </Button>
              }
              className={`profile-pic ${smallScreen && "mt-6"}`}
              loading={!dataCompany || skeletonCompany}
            >
              <CompanyInformation propsCompany={propsCompany} />
              <ModalInfoCompany propsModal={propsModal} />
            </Card>
            <Row gutter={10}>
              <Col xl={12} lg={24} md={24} sm={24} xs={24}>
                <Card
                  title="Informasi Kontak"
                  extra={
                    <Button type="primary" icon={<ICPlusOutlined />} disabled>
                      Tambah Kontak
                    </Button>
                  }
                  className="profile-pic"
                  loading={!dataContact || skeletonContact}
                >
                  <ContactInformation dataContact={dataContact?.data?.rows} />
                </Card>
              </Col>
              <Col xl={12} lg={24} md={24} sm={24} xs={24}>
                <Card
                  title="Detail Kuota"
                  extra={
                    <Button
                      type="primary"
                      icon={<ICCreditCardOutlined />}
                      disabled={dataQuota?.statusCode === 400}
                      target="_blank"
                      href="https://www.aktiva.co.id/email-validator-order/"
                    >
                      Tambah Kuota
                    </Button>
                  }
                  className={`profileQuota ${!Xlargescreen && "mt-3"}`}
                  loading={!dataQuota}
                >
                  <DetailQuota propsQuota={propsQuota} propsUser={propsUser} />
                </Card>
              </Col>
            </Row>
          </Flex>
        </Col>
      </Row>
    </>
  );
};

export default Profile;
