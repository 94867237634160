import { Modal, Typography, Form, Input } from "antd";
import { noSpaceRegex } from "../../utils/helpers";
import { useState } from "react";
import ModalSuccess from "./ModalSuccess";
import ModalWarning from "./ModalWarning";
import { addApiKey } from "../../api";

const { Text } = Typography;
export const ModalAddApiKey = ({ props, form }) => {
  const [loading, setLoading] = useState(false);

  const {
    showModalKey,
    closeModal,
    nameValue,
    setNameValue,
    descValue,
    setDescValue,
  } = props;

  const handleAddKey = async () => {
    setLoading(true);
    const { statusCode, data } = await addApiKey({
      name: nameValue,
      description: descValue,
    });
    if (statusCode === 200) {
      setLoading(false);
      ModalSuccess({
        title: "API Key berhasil dibuat",
        customMessage: (
          <span>
            API key <span className="font-medium">{data?.name}</span> berhasil
            dibuat pada sistem. Anda dapat menggunakannya untuk melakukan
            integrasi validasi email
          </span>
        ),
        type: "primary",
        width: 500,
        onOk: () => closeModal({ process: "success" }),
      });
    } else if (statusCode === 400) {
      setLoading(false);
      ModalWarning({
        title: "API key sudah mencapai limit",
        customMessage:
          "Anda sudah membuat 5 API key pada akun anda. Saat ini anda tidak dapat membuat API key baru lagi",
        type: "primary",
        width: 500,
        onOk: () => closeModal({}),
      });
    } else {
      setLoading(false);
      ModalWarning({
        title: "API Key gagal dibuat",
        customMessage:
          "Gagal membuat API key tersebut. Terdapat kendala pada sistem, harap coba lagi",
        type: "primary",
        width: 500,
        onOk: () => closeModal({}),
      });
    }
  };

  return (
    <Modal
      centered
      title="Create API Key"
      okText="Create API key"
      cancelText="Batal"
      width={572}
      open={showModalKey}
      onCancel={closeModal}
      okButtonProps={{ disabled: !(nameValue && descValue) }}
      onOk={handleAddKey}
      confirmLoading={loading}
    >
      <Form form={form} layout="vertical">
        <Text className="text-sm">
          Buat API Key anda dan mulai integrasi untuk validasi email
        </Text>
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              pattern: noSpaceRegex,
              message: "Nama tidak dapat menggunakan spasi",
            },
          ]}
          className="pt-3 mb-3"
          onChange={(e) => setNameValue(e.target.value)}
        >
          <Input placeholder="API key name" />
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
          onChange={(e) => setDescValue(e.target.value)}
        >
          <Input placeholder="API key description" />
        </Form.Item>
      </Form>
    </Modal>
  );
};
