import {
  Flex,
  Image,
  Button,
  Typography,
  Tag,
  Divider,
  Descriptions,
  Row,
  Col,
  Progress,
} from "antd";
import {
  ICUserOutlined,
  ICEditOutlined,
  ICUnlockOutlined,
  ICMailOutlined,
} from "../../../components/list/ListIcon";
import profilePic from "../../../assets/images/profile_picture.svg";
import emptyCompany from "../../../assets/images/personal_data.svg";
import { formatNumberWithDot } from "../../../utils/helpers";
import { BtnClaimQuota } from "../../AntButton";

const { Title, Text } = Typography;

export const ProfileDetail = ({ propsUser }) => {
  const { name, email, setOpenModalChange, setOpenModalContact } = propsUser;
  return (
    <>
      <Flex justify="center">
        <Image src={profilePic} preview={false} />
      </Flex>
      <Flex align="center" gap={5} className="pt-4">
        <ICUserOutlined />
        <Text>{name}</Text>
      </Flex>
      <Flex align="center" gap={5} className="pt-2">
        <ICMailOutlined />
        <Text>{email}</Text>
      </Flex>
      <Flex vertical gap={8} className="pt-4">
        <Button
          icon={<ICEditOutlined />}
          type="primary"
          onClick={() => setOpenModalContact(true)}
        >
          Edit Profil
        </Button>
        <Button
          icon={<ICUnlockOutlined />}
          onClick={() => setOpenModalChange(true)}
        >
          Ubah Password
        </Button>
      </Flex>
    </>
  );
};

export const CompanyInformation = ({ propsCompany }) => {
  const { name, address, phone, website } = propsCompany;
  const noData = (name && address && phone && website) === "";
  return (
    <>
      {noData ? (
        <>
          <Flex justify="center">
            <Image src={emptyCompany} preview={false} />
          </Flex>
          <Flex vertical align="center" gap={4} className="pt-3">
            <Text className="font-medium text-base">
              Data Perusahaan Belum Dilengkapi
            </Text>
            <Text className="text-secondary text-center pb-2">
              Silakan dapat melengkapi data informasi perusahaan anda.
            </Text>
            <Button
              type="primary"
              icon={<ICEditOutlined />}
              onClick={() => (window.location.href = "/register/company-data")}
            >
              Lengkapi Data
            </Button>
          </Flex>
        </>
      ) : (
        <Descriptions
          layout="horizontal"
          column={1}
          bordered={false}
          className="desc-company"
          labelStyle={{
            width: 100,
            fontWeight: 500,
            color: "black",
            padding: "0px 16px",
          }}
          colon={false}
        >
          <Descriptions.Item label="Nama">{name}</Descriptions.Item>
          <Descriptions.Item label="Alamat">{address}</Descriptions.Item>
          <Descriptions.Item label="Telepon">{phone}</Descriptions.Item>
          <Descriptions.Item label="Website">
            https://{website}
          </Descriptions.Item>
        </Descriptions>
      )}
    </>
  );
};

export const ContactInformation = ({ dataContact }) => {
  return (
    <>
      {dataContact &&
        dataContact.map((userContact) => (
          <>
            <Flex justify="space-between">
              <Flex vertical gap={4}>
                <Text className="font-medium text-sm">{userContact.name}</Text>
                <Text className="font-medium text-xs">{userContact.email}</Text>
              </Flex>
              <Flex vertical align="end" gap={4}>
                <Text className="text-sm">{userContact.phone}</Text>
                {userContact.role && (
                  <Tag color="blue" className="tag-contact mr-0">
                    {userContact.role}
                  </Tag>
                )}
              </Flex>
            </Flex>
            <Divider className="mb-0" />
          </>
        ))}
    </>
  );
};


export const DetailQuota = ({ propsQuota, propsUser }) => {
  const {
    basicQuotaUsage,
    basicQuotaTotal,
    basicPrecentage,
    advanceQuotaUsage,
    advanceQuotaTotal,
    advancePrecentage,
    totalQuotaAvailable,
    totalUsagePercentage,
    noQuota,
    isClaimQuota,
  } = propsQuota;

  const {companyUUID } = propsUser;

  const colorStrokeBasic =
    basicPrecentage <= 80
      ? "#0D9F3F"
      : basicPrecentage <= 90
      ? "#FAAD14"
      : "#FF4D4F";

  const colorStrokeAdvanced =
    advancePrecentage <= 80
      ? "#0D9F3F"
      : advancePrecentage <= 90
      ? "#FAAD14"
      : "#FF4D4F";

  return (
    <>
      {noQuota ? (
        <>
          <Flex vertical align="center" gap={8}>
            <Text className="color-secondary-25 text-base pt-2">
              Tidak ada kuota tersedia
            </Text>
            {!isClaimQuota && <BtnClaimQuota companyUUID={companyUUID} />}
            <Divider className="my-2" />
          </Flex>
        </>
      ) : (
        <>
          <Row className="pb-4">
            <Col span={12}>
              <Flex vertical align="center">
                <Title
                  level={2}
                  className="title-precentage my-0"
                  style={{ marginBottom: 0 }}
                >
                  {totalUsagePercentage}%
                </Title>
                <Text className="text-xs text-secondary">Penggunaan kuota</Text>
              </Flex>
            </Col>
            <Col span={12}>
              <Flex vertical align="center">
                <Title
                  level={2}
                  className="title-precentage my-0"
                  style={{ marginBottom: 0 }}
                >
                  {formatNumberWithDot(totalQuotaAvailable)}
                </Title>
                <Text className="text-xs text-secondary">Kredit Tersedia</Text>
              </Flex>
            </Col>
          </Row>
          <Flex justify="space-between">
            <Text className="font-normal">Basic Validation</Text>
            <Text className="text-xs font-medium text-secondary">
              {formatNumberWithDot(basicQuotaUsage)}/
              {formatNumberWithDot(basicQuotaTotal)} ({basicPrecentage}%)
            </Text>
          </Flex>
          <Progress
            percent={basicPrecentage}
            strokeColor={colorStrokeBasic}
            showInfo={false}
            className="profileQuota"
          />
          <Flex justify="space-between" className="pt-4">
            <Text className="font-normal">Advanced Validation</Text>
            <Text className="text-xs font-medium text-secondary">
              {formatNumberWithDot(advanceQuotaUsage)}/
              {formatNumberWithDot(advanceQuotaTotal)} ({advancePrecentage}%)
            </Text>
          </Flex>
          <Progress
            percent={advancePrecentage}
            strokeColor={colorStrokeAdvanced}
            showInfo={false}
            className="profileQuota"
          />
        </>
      )}
    </>
  );
};
